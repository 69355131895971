import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { GridColumn, GridSetup } from '..';
import { GridFlatRow } from '../interfaces/grid-flat-row';


@Component({
	selector: 'dashboard-grid-cell',
	standalone: true,
	imports: [NgClass],
	templateUrl: './dashboard-grid-cell.part.html',
	styleUrls: ['dashboard-grid-cell.part.scss'],
})
export class DashboardGridCellPart<RowT extends { updatedUTC: number }, ValueT> {

	public setup = input.required<GridSetup<RowT>>();
	public column = input.required<GridColumn<RowT, ValueT>>();
	public flatRow = input.required<GridFlatRow<RowT>>();

	public tooltip: string | undefined = undefined;

	classes = computed(() => {

		const setup = this.setup();
		const flatRow = this.flatRow();
		const column = this.column();
		const value = column.getValue(flatRow.row);
		const renderRowClasses = setup.renderRowClasses;
		const renderCellClasses = column.renderCellClasses;

		const c: { [index: string]: boolean } = {};

		const rowClasses = renderRowClasses ? renderRowClasses(flatRow.row) : [];
		for (const cellClass of rowClasses) { c[cellClass] = true; }

		const cellClasses = renderCellClasses ? renderCellClasses(flatRow.row, value) : [];
		for (const cellClass of cellClasses) { c[cellClass] = true; }

		if (column.noColumnMenu) c['no-column-menu'] = true;

		return c;
	});


	iconClasses = computed(() => {

		const flatRow = this.flatRow();
		const column = this.column();
		const value = column.getValue(flatRow.row);
		const renderIconClasses = column.renderIconClasses;

		const iconClasses = renderIconClasses ? renderIconClasses(flatRow.row, value) : undefined;
		return iconClasses;
	});

}