import { UtilityService } from "@eforall/common";
import { GridColumn } from "../interfaces";


export type boolCol
	= 'TrueFalse'
	| 'YesNo';

export type dateCol
	= 'Date'
	| 'DateTime'
	| 'Time';

export type numberCol
	= 'Id'
	| 'Number'
	| 'Percent';

export type stringCol
	= 'Email'
	| 'FirstName'
	| 'FullName'
	| 'LastName'
	| 'Phone'
	| 'Status'
	| 'Text'
	| 'Type';


/**
 * Given a named column configuration, lookup and return the default column configuration.
 */
export function getColConfig<RowT extends { updatedUTC: number }>(util: UtilityService, col: boolCol | dateCol | numberCol | stringCol) {

	/** A GridColumn without the getValue and flatRowField properties */
	type GridColumnConfig<RowT extends { updatedUTC: number }, ValueT> = Omit<GridColumn<RowT, ValueT>, 'getValue' | 'flatRowField'>;

	type BoolConfigRecord = { [key in boolCol]: GridColumnConfig<RowT, boolean> };
	type DateConfigRecord = { [key in dateCol]: GridColumnConfig<RowT, Date> };
	type NumberConfigRecord = { [key in numberCol]: GridColumnConfig<RowT, number> };
	type StringConfigRecord = { [key in stringCol]: GridColumnConfig<RowT, string> };


	const boolConfigs: BoolConfigRecord = {
		'TrueFalse': { filterType: 'boolean', header: 'T/F', width: 60, renderDisplay: (row, value) => value == undefined ? undefined : value ? 'True' : 'False' },
		'YesNo': { filterType: 'boolean', header: 'Y/N', width: 60, renderDisplay: (row, value) => value == undefined ? undefined : value ? 'Yes' : 'No' },
	};

	const dateConfigs: DateConfigRecord = {
		'Date': { filterType: 'date', header: 'Date', width: 100, renderDisplay: (row, value, util) => util.date.formatDate(value, 'MMM D, YYYY', 'en-US') },
		'DateTime': { filterType: 'date', header: 'Date', width: 200, renderDisplay: (row, value) => util.date.formatDateAndTime(value, 'MMM D, YYYY', 'en-US') },
		'Time': { filterType: 'date', header: 'Time', width: 100, renderDisplay: (row, value) => util.date.formatTime(value, 'en-US') },
	};

	const numberConfigs: NumberConfigRecord = {
		'Id': { filterType: 'numeric', header: 'Id', width: 90, hidden: true, renderCellClasses: (row, value) => ['grid-cell-right-align'] },
		'Number': { filterType: 'numeric', header: 'Number', width: 80, renderCellClasses: (row, value) => ['grid-cell-right-align'] },
		'Percent': { filterType: 'numeric', header: 'Percent', width: 80, renderCellClasses: (row, value) => ['grid-cell-right-align'], renderDisplay: (row, value) => value == undefined ? undefined : value + '%' },
	}

	const stringConfigs: StringConfigRecord = {
		'Email': { filterType: 'text', header: 'Email', width: 200 },
		'FirstName': { filterType: 'text', header: 'First', width: 90 },
		'FullName': { filterType: 'text', header: 'Name', width: 170 },
		'LastName': { filterType: 'text', header: 'Last', width: 120 },
		'Phone': { filterType: 'text', header: 'Phone', width: 150 },
		'Status': { filterType: 'text', header: 'Status', width: 120 },
		'Text': { filterType: 'text', header: 'Text', width: 120 },
		'Type': { filterType: 'text', header: 'Type', width: 120 },
	};

	const configs = {
		...boolConfigs,
		...dateConfigs,
		...numberConfigs,
		...stringConfigs,
	};


	return configs[col] as GridColumnConfig<RowT, unknown>;
}

