import { AuthState } from "./auth-state";


/**
 * Compare two auth state objects and determine if they are equivalent
 */
export function authStateEquality(s1: AuthState, s2: AuthState) {

	if (s1.status !== s2.status) return false;
	if (s1.status == 'error') return false;

	if (s1.status == 'signed-in' && s2.status == 'signed-in') {
		if (s1.user.emailVerified !== s2.user.emailVerified) return false;
		if (s1.user.email !== s2.user.email) return false;
	}

	return true;
}