import { JobTrack, OrgLocation, Pronouns } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { FuncData } from '@interfaces';
import { Department, JobRole, Staff } from '../app-interfaces';


export function buildStaffData(data: FuncData, util: UtilityService) {

	//
	// Locations
	//
	const locations: OrgLocation[] = data.locations.map(loc => ({ ...loc, staffCount: 0, staffPercent: 0 }));
	const locationById = util.array.toMap(locations, loc => loc.locationId);

	//
	// Pronouns
	//
	const pronouns: Pronouns[] = data.pronouns.map(pn => ({ ...pn, staffCount: 0, staffPercent: 0 }));
	const pronounsById = util.array.toMap(pronouns, pn => pn.pronounsId);

	//
	// Job Tracks
	//
	const jobTracks: JobTrack[] = data.jobTracks.map(trk => ({ ...trk, roleCount: 0, staffCount: 0, staffPercent: 0 }));
	const jobTrackById = util.array.toMap(jobTracks, trk => trk.jobTrackId);

	//
	// Job Roles
	//
	const jobRoles: JobRole[] = data.jobRoles.map(role => ({ ...role, jobTrack: jobTrackById[role.jobTrackId]!, staffCount: 0, staffPercent: 0 }));
	const jobRoleById = util.array.toMap(jobRoles, role => role.jobRoleId);

	//
	// Staff
	//
	const staff: Staff[] = data.staff.map(staff => ({
		...staff,
		fullName: staff.firstName + ' ' + staff.lastName,
		jobRole: jobRoleById[staff.jobRoleId ?? 0],
		location: locationById[staff.locationId ?? 0],
		pronouns: pronounsById[staff.pronounsId ?? 0],
	}));

	const staffById = util.array.toMap(staff, staff => staff.staffId);

	//
	// Departments
	//
	const departments: Department[] = data.departments.map(dept => ({ ...dept, head: staffById[dept.headStaffId]!, staffCount: 0, staffPercent: 0 }));
	const departmentById = util.array.toMap(departments, dept => dept.departmentId);

	//
	// Tack manager and department on to staff
	//
	for (const member of staff) {

		member.manager = staffById[member.managerStaffId ?? 0];
		member.department = departmentById[member.departmentId ?? 0];
	}

	//
	// Department counts
	//
	const staffByDepartmentId = util.array.toArrayMap(staff, staff => staff.departmentId ?? 0);
	for (const dept of departments) {
		dept.staffCount = (staffByDepartmentId[dept.departmentId] ?? []).length;
		dept.staffPercent = Math.floor(dept.staffCount / staff.length * 100);
	}

	//
	// JobTrack counts
	//
	const staffByJobTrackId = util.array.toArrayMap(staff, staff => staff.jobRole?.jobTrackId ?? 0);
	const rolesByJobTrackId = util.array.toArrayMap(jobRoles, role => role.jobTrackId ?? 0);
	for (const trk of jobTracks) {
		trk.roleCount = (rolesByJobTrackId[trk.jobTrackId] ?? []).length;
		trk.staffCount = (staffByJobTrackId[trk.jobTrackId] ?? []).length;
		trk.staffPercent = Math.floor(trk.staffCount / staff.length * 100);
	}


	//
	// JobRole counts
	//
	const staffByJobRoleId = util.array.toArrayMap(staff, staff => staff.jobRoleId ?? 0);
	for (const role of jobRoles) {
		role.staffCount = (staffByJobRoleId[role.jobRoleId] ?? []).length;
		role.staffPercent = Math.floor(role.staffCount / staff.length * 100);
	}

	//
	// Location counts
	//
	const staffByLocationId = util.array.toArrayMap(staff, staff => staff.locationId ?? 0);
	for (const loc of locations) {
		loc.staffCount = (staffByLocationId[loc.locationId] ?? []).length;
		loc.staffPercent = Math.floor(loc.staffCount / staff.length * 100);
	}

	//
	// Pronouns counts
	//
	const staffByPronounsId = util.array.toArrayMap(staff, staff => staff.pronounsId ?? 0);
	for (const pn of pronouns) {
		pn.staffCount = (staffByPronounsId[pn.pronounsId] ?? []).length;
		pn.staffPercent = Math.floor(pn.staffCount / staff.length * 100);
	}


	return {
		departmentById,
		departments,
		jobRoles,
		jobTracks,
		locations,
		pronouns,
		staffById,
		staff,
	};
}