
import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonMultiSelectField } from '../field';

@Component({
	selector: 'common-multiselect-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-multiselect-field.widget.html',
})
export class CommonMultiselectFieldWidget<T> extends CommonFieldWidgetBase<T[], CommonMultiSelectField<T>> implements OnInit {
	private util = inject(UtilityService);

	public readonly field = input.required<CommonMultiSelectField<T>>();

	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			invalidSelection: 'Invalid Selection',
		} : {
			missingRequired: `Required field`,
			invalidSelection: 'Invalid Selection',
		};

	});



	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T, checked: boolean) {
		//
		// On adding an input remove any selection that doesn't exist in the options.
		// E.g. Race other 'O' was removed but we still have the value set.
		//

		const values = this.field().pendingValue() ?? this.field().actualValue();

		const filteredModel = values.filter(data => this.field().config().options.map(opt => opt.value).includes(data));

		if (checked) {
			const model: T[] = [...filteredModel, value];
			this.field().pendingValue.set(model);
		}
		else {
			const model = filteredModel.filter(data => data !== value);
			this.field().pendingValue.set(model);
		}
	}

	public async onBlur() {
		await this.blur(this.field());
	}

	protected renderValue(value: T) {
		const values = this.field().pendingValue() ?? this.field().actualValue();

		if (values.find(v => v == value)) return true;
		else return false;
	}


	protected validate(): string {

		const values = this.getValue(this.field());
		const config = this.field().config();
		const labels = this.labels();

		if (!values.length && config.required) return labels.missingRequired;
		if (values.length && values.find(v => !config.options.map(opt => opt.value).includes(v))) return labels.invalidSelection;

		return '';
	}

	protected override valuesAreSame(value1: T[], value2: T[]): boolean {
		return this.util.values.areSame(value1 || [], value2 || []);
	}
}