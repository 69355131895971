import { Signal, computed, effect } from "@angular/core";
import { State, process } from "@progress/kendo-data-query";
import { GridColumn, GridFlatRow, GridSetup } from "../../interfaces";
import { flatRowArrayEquality } from "../equality/flat-row-array-equality";
import { GridSelectionManager } from "./grid-selection-manager";
import { GridStateManager } from "./grid-state-manager";


/**
 * Row objects might be compound.  e.g. col.getValue() might return
 * a value like staff.department.head.firstName;  However, the Kendo
 * grid expects a simple string field name.  So, we map each row object
 * to a simple flat object where each column's value is tacked on with
 * a simple "col#" property.
 */
export class GridFlatRowsManager<RowT extends { updatedUTC: number }> {


	constructor(
		private readonly setup: Signal<GridSetup<RowT>>,
		private readonly rows: Signal<RowT[]>,
		private readonly state: GridStateManager<RowT>,
		private readonly selection: GridSelectionManager<RowT>,
	) {

		effect(() => {
			console.log(`All Flat Rows`, this.all());
		});
	}


	/**
	 * Transform a regular Row object into a flat version
	 * that works with the Kendo grid.
	 */
	private flattenRow(row: RowT): GridFlatRow<RowT> {

		const setup = this.setup();
		const getRowKey = setup.getRowKey;

		const flatRow: GridFlatRow<RowT> = {
			rowKey: getRowKey(row),
			updatedUTC: row.updatedUTC,
			numCols: setup.columns.length,
			row,
		};

		for (const col of setup.columns ?? []) {
			flatRow[col.flatRowField] = col.getValue(row);
		}

		return flatRow;
	}


	/**
	 * All rows in flat form.
	 */
	public readonly all = computed(() => {

		const rows = this.rows();
		return rows.map(row => this.flattenRow(row));

	}, { equal: flatRowArrayEquality });


	/**
	 * Filtered rows in flat form.
	 */
	public readonly filtered = computed<GridFlatRow<RowT>[]>(() => {

		const state: State = { filter: this.state.get().filter };
		const filteredRows = process(this.all(), state);
		return filteredRows.data;

	}, { equal: flatRowArrayEquality });


	/**
	 * Selected rows in flat form.
	 */
	public readonly selected = computed<GridFlatRow<RowT>[]>(() => {

		const selectedKeys = this.selection.selectedKeys();
		const rows = this.filtered().filter(row => selectedKeys.includes(row.rowKey));
		return rows;

	}, { equal: flatRowArrayEquality });
}