import { JobTrack } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<JobTrack, unknown>[] = [
		buildColumn(util, 'Id', row => row.jobTrackId, { header: 'jobTrackId' }),
		buildColumn(util, 'Text', row => row.name, { header: 'Track' }),
		buildColumn(util, 'Number', row => row.roleCount, { header: 'Roles', headerTooltip: 'Count of Job Roles in this Job Track' }),
		buildColumn(util, 'Number', row => row.staffCount, { header: 'Staff', headerTooltip: 'Count of Staff with this Job Track' }),
		buildColumn(util, 'Percent', row => row.staffPercent, { header: 'Staff %', headerTooltip: 'Percentage of Staff with this Job Track' }),
		buildColumn(util, 'DateTime', row => util.date.fromUTC(row.updatedUTC), { hidden: true, header: 'Updated' }),
	];


	return columns;

}