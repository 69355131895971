
import { Signal, computed, signal } from "@angular/core";
import { DataStateChangeEvent, PageChangeEvent } from "@progress/kendo-angular-grid";
import { CompositeFilterDescriptor, FilterDescriptor, FilterOperator, GroupDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import { GridSetup } from "../../interfaces";
import { GridSort } from "../../interfaces/grid-sort";


interface GridState extends State {
	skip: number,
	take: number,
	sort: Array<SortDescriptor>,
	group: Array<GroupDescriptor>,
	filter: CompositeFilterDescriptor
}

export const DEFAULT_STATE: GridState = { skip: 0, take: 50, group: [], sort: [], filter: { filters: Array(0), logic: 'and' } };


export class GridStateManager<RowT extends { updatedUTC: number }> {

	constructor(private readonly setup: Signal<GridSetup<RowT>>) { }

	private readonly _state = signal<Partial<GridState> | undefined>(undefined);

	public readonly get = computed<GridState>(() => {

		const setup = this.setup();


		let state = DEFAULT_STATE;

		//
		// If we have already rendered this grid in this session, and there is a stateKey,
		// then we'll use the prior state so things like grouping, filtering, and sorting
		// are retained.
		//
		if (setup.stateKey && sessionStorage.getItem(setup.stateKey)) {
			const storedState = sessionStorage.getItem(setup.stateKey);
			if (storedState) state = JSON.parse(storedState);
		}


		//
		// If this is the first rendering of this grid for this session, check if there is
		// an initial state defined in the setup.  If so, merge it into the default state.
		//
		else {
			state = { ...state, ...this.calcInitialState(setup) };
		}


		const changedState = this._state();
		if (changedState) return state = { ...state, ...changedState };


		//
		// Add a count calculation to each grouping
		//
		for (const group of state.group || []) {
			group.aggregates = [
				{ field: group.field, aggregate: 'count' },
			];
		}


		//
		// Remember the state for this grid
		//
		if (sessionStorage && setup.stateKey) sessionStorage.setItem(setup.stateKey, JSON.stringify(state));


		return state;
	});

	public merge(state: Partial<GridState>) {
		this._state.update(changedState => ({ ...changedState, ...state }));
	}

	public onChange(state: DataStateChangeEvent): void {
		const newState: GridState = {
			skip: state.skip,
			take: state.take,
			sort: state.sort || DEFAULT_STATE.sort,
			group: state.group || DEFAULT_STATE.group,
			filter: state.filter || DEFAULT_STATE.filter,
		}

		this.merge(newState);
	}


	public onPageChange(event: PageChangeEvent): void {
		this.merge({ skip: event.skip });
	}


	private calcInitialState(setup: GridSetup<RowT>): State {

		const state: State = {};

		//
		// Initial Column Sorting
		//
		const sorted: SortDescriptor[] = setup.columns
			.filter(c => c.sort !== undefined)
			.map(col => {
				let sort: GridSort = { order: 1, dir: 'asc' };
				if (col.sort == 'asc' || col.sort == 'desc') sort = { order: 1, dir: col.sort };
				else if (col.sort) sort = col.sort;
				return { sort, col };
			})
			.sort((c1, c2) => c1.sort.order - c2.sort.order)
			.map(c => ({ field: c.col.flatRowField, dir: c.sort.dir }));

		if (sorted.length) state.sort = sorted;


		//
		// Initial Column Grouping
		//
		const grouped: GroupDescriptor[] = setup.columns
			.filter(c => c.group !== undefined)
			.map(col => {
				let group: GridSort = { order: 1, dir: 'asc' };
				if (col.group == 'asc' || col.group == 'desc') group = { order: 1, dir: col.group };
				else if (col.group) group = col.group;
				return { sort: group, col };
			})
			.sort((c1, c2) => c1.sort.order - c2.sort.order)
			.map(c => ({ field: c.col.flatRowField, dir: c.sort.dir }));

		if (grouped.length) state.group = grouped;


		//
		// Initial Column Filtering
		//
		const filtered: FilterDescriptor[] = setup.columns
			.filter(c => c.filter !== undefined)
			.map(c => {
				const filter = c.filter!;
				return { field: c.flatRowField, ...filter }
			});

		if (filtered.length) state.filter = {
			logic: 'and',
			filters: filtered,
		};


		return state;
	}
}