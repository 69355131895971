import { DashboardSectionMenu } from '@eforall/dashboard';

export const INTERACTION_MENU: DashboardSectionMenu = {
	label: 'Interaction',
	items: [
		{
			label: 'Messages',
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-message-captions',
			routerLink: '/messages'
		},
		{
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-calendar',
			label: 'Calendar',
			routerLink: '/calendar',
		},
	]
}