<div class="outer">
	<div class="inner">
		<div class="swoop one"></div>
	</div>
	<div class="inner">
		<div class="swoop one shadow"></div>
	</div>
	<div class="inner">
		<div class="swoop one highlight"></div>
	</div>
	<div class="inner">
		<div class="swoop two"></div>
	</div>
	<div class="inner">
		<div class="swoop two shadow"></div>
	</div>
	<div class="inner">
		<div class="swoop two highlight"></div>
	</div>
	<div class="inner">
		<div class="swoop three"></div>
	</div>
	<div class="inner">
		<div class="swoop three shadow"></div>
	</div>
	<div class="inner">
		<div class="swoop three highlight"></div>
	</div>
	<div class="inner">
		<div class="swoop four"></div>
	</div>
	<div class="inner">
		<div class="swoop four shadow"></div>
	</div>
	<div class="inner">
		<div class="swoop four highlight"></div>
	</div>
</div>