import { Component, OnInit, computed, effect, inject, input, signal } from '@angular/core';
import { UtilityService } from '../../../../services';
import { CommonEmailField } from '../field';
import { FormsModule } from '@angular/forms';
import { CommonFieldWidgetBase } from '../common-field-widget-base';

@Component({
	selector: 'common-email-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-email-field.widget.html',
})
export class CommonEmailFieldWidget extends CommonFieldWidgetBase<string, CommonEmailField> implements OnInit {

	public readonly field = input.required<CommonEmailField>();

	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			notValid: 'Spanish not valid email',
		} : {
			missingRequired: `Required field`,
			notValid: `Invalid email address`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	private emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		this.field().pendingValue.set(value);
	}


	public async onBlur() {
		await this.blur(this.field());
	}


	protected override cleanValue(value: string) {
		return value.trim().toLowerCase();
	}
	

	protected validate(): string {

		const value = (this.getValue(this.field())).trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.required) return labels.missingRequired;
		if (value.length && !value.match(this.emailPattern)) return labels.notValid;

		return '';
	}
}