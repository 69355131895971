import { Component, input, OnInit, output, signal } from '@angular/core';

@Component({
	selector: 'common-nps-selector',
	standalone: true,
	templateUrl: './common-nps-selector.widget.html',
	styleUrls: ['./common-nps-selector.widget.scss'],
})
export class CommonNetPromoterScoreWidget implements OnInit {


	public key = input.required<string>();
	public value = input.required<number | undefined>();
	public updateValue = output<{ key: string, value: number }>();

	public netPromoter = signal<number | undefined>(undefined);
	saving = false;


	ngOnInit() {
		this.netPromoter.set(this.value());
	}


	public scoreChanged(value: number) {

		if (value !== undefined) {
			this.saving = true;
			this.netPromoter.set(value);
			this.saving = false;

			this.updateValue.emit({ value, key: this.key() });
		}
	}
}
