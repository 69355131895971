import { JobRole } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<JobRole, unknown>[] = [
		buildColumn(util, 'Id', row => row.jobTrackId, { header: 'jobRoleId' }),
		buildColumn(util, 'Text', row => row.jobTrack.name, { header: 'Track' }),
		buildColumn(util, 'Text', row => row.en, { header: 'English' }),
		buildColumn(util, 'Text', row => row.es, { header: 'Spanish' }),
		buildColumn(util, 'Number', row => row.staffCount, { header: 'Staff', headerTooltip: 'Count of Staff with this Job Role' }),
		buildColumn(util, 'Percent', row => row.staffPercent, { header: 'Staff %', headerTooltip: 'Percentage of Staff with this Job Role' }),
		buildColumn(util, 'DateTime', row => util.date.fromUTC(row.updatedUTC), { hidden: true, header: 'Updated' }),
	];


	return columns;

}