import { EventEmitter, Signal, computed } from "@angular/core";
import { GridSetup } from "../interfaces";
import { CtrlAltShift, GridClickManager } from "./managers/grid-click-manager";
import { GridFiltersManager } from "./managers/grid-filters-manager";
import { GridFlatRowsManager } from "./managers/grid-flat-rows-manager";
import { GridGroupableManager } from "./managers/grid-groupable-manager";
import { GridSelectionManager } from "./managers/grid-selection-manager";
import { GridStateManager } from "./managers/grid-state-manager";
import { setFlatRowFields } from "./set-flat-row-fields";


export class GridController<RowT extends { updatedUTC: number; }> {

	public readonly doubleClick = new EventEmitter<{ keys: CtrlAltShift, row: RowT }>();
	public readonly contextMenu = new EventEmitter<{ left: number, top: number } | undefined>();

	public readonly state: GridStateManager<RowT>;
	public readonly selection: GridSelectionManager<RowT>;
	public readonly click: GridClickManager<RowT>;
	public readonly filters: GridFiltersManager<RowT>;
	public readonly flatRows: GridFlatRowsManager<RowT>;
	public readonly groupable: GridGroupableManager<RowT>;


	constructor(
		public readonly setup: Signal<GridSetup<RowT>>,
		public readonly rows: Signal<RowT[]>,
	) {

		/**
		 * Map the GridSetup to a new one where each column
		 * has the flatRowField set correctly.
		 */
		const setupWithFlatColumnNames = computed(() => {
			const s = setup();
			setFlatRowFields(s.columns);
			return { ...s };
		});


		this.state = new GridStateManager<RowT>(setupWithFlatColumnNames);
		this.selection = new GridSelectionManager<RowT>(setupWithFlatColumnNames, this.rows);
		this.click = new GridClickManager<RowT>(setupWithFlatColumnNames, this.rows, this.selection, this.doubleClick, this.contextMenu);
		this.filters = new GridFiltersManager<RowT>(this.state, this.selection);
		this.flatRows = new GridFlatRowsManager<RowT>(setupWithFlatColumnNames, rows, this.state, this.selection);
		this.groupable = new GridGroupableManager<RowT>(setupWithFlatColumnNames, this.state);
	}
}