import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, OutputRefSubscription, computed, inject, input, viewChild } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ColumnComponent, GridComponent, GridModule, RowArgs } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, GroupResult } from '@progress/kendo-data-query';
import { DialogService } from '../../../services';
import { DashboardLayoutService } from '../../frame/layout/dashboard-layout.service';
import { DashboardGridCellPart } from './cell/dashboard-grid-cell.part';
import { GridController } from './controller';
import { GridFilterDialog } from './filter-dialog/filter.dialog';
import { GridAction, GridColumn, GridFlatRow, GridSetup, GridSize } from './interfaces';
import { DashboardGridMenuPart } from './menu/dashboard-grid-menu.part';


@Component({
	selector: 'dashboard-grid',
	standalone: true,
	imports: [
		ButtonsModule,
		CommonModule,
		DashboardGridCellPart,
		DashboardGridMenuPart,
		DropDownsModule,
		GridModule,
	],
	templateUrl: './dashboard-grid.widget.html',
	styleUrls: ['dashboard-grid.widget.scss'],
})
export class DashboardGridWidget<RowT extends { updatedUTC: number }> implements OnInit, OnDestroy {

	public layoutService = inject(DashboardLayoutService);
	private dialogService = inject(DialogService);
	public util = inject(UtilityService);

	gridComponent = viewChild.required<GridComponent>(GridComponent);

	public setup = input.required<GridSetup<RowT>>();
	public size = input.required<GridSize>();
	public rows = input.required<RowT[]>();
	public loading = input(false);

	public grid = new GridController<RowT>(this.setup, this.rows);

	/** Calculated font awesome icon based on whether there are filters */
	public filterButtonClass = computed(() => this.grid.filters.current()?.filters?.length ? 'fa-fw fas fa-filter' : 'fa-fw far fa-filter');

	public counts = computed(() => this.updateCounts());  // e.g.  '1,000 people' or '21 of 79 mentors'

	private subs?: OutputRefSubscription;


	/** Transforms the size input to a set of styles for ngStyle */
	public gridStyle = computed(() => {
		const size = this.size();
		return {
			"width": `${size.width}px`,
			"marginTop": `${size.marginTop}px`,
			"marginRight": `${size.marginRight}px`,
			"marginBottom": `${size.marginBottom}px`,
			"marginLeft": `${size.marginLeft}px`,
			"border": 'none',
			"background-color": '#FFF',
		};
	});

	private selectedRowIds = computed(() => {
		const rows = this.grid.selection.selectedRows();
		const selectedRowIds = [...rows.map(row => this.setup().getRowKey(row))];
		return selectedRowIds;
	});



	public isActionEnabled(action: GridAction<RowT>) {
		const rows = this.grid.selection.selectedRows();
		if (rows.length == 0) return false;
		return action.isEnabled(rows);
	}


	async ngOnInit() {

		this.subs = this.grid.doubleClick.subscribe(async ({ keys, row }) => {

			const action = this.setup().actions[0];

			if (!row) console.error('Grid double click without a selected row.');
			else if (!action) console.error('Grid double click without any actions.');
			else if (action.isEnabled([row])) {
				console.log(`DOUBLE CLICK`);
				action.execute([row]);
			}
		});

		this.grid.groupable.setGridComponent(this.gridComponent());
	}


	ngOnDestroy() {
		this.grid.groupable.setGridComponent(undefined);
		this.subs?.unsubscribe();
	}


	/**
	 * Transform a cell value into something suitable to be displayed
	 */
	renderCell(flatRow: GridFlatRow<RowT>, col: GridColumn<RowT, unknown>, util: UtilityService) {

		const value = flatRow[col.flatRowField];
		const renderDisplay = col.renderDisplay ?? ((row, value) => value?.toLocaleString());
		return renderDisplay(flatRow.row, value, util);
	}


	async changeFilter() {

		const action = await this.dialogService.showCustom(
			GridFilterDialog,
			{ filters: this.grid.filters.current(), columns: this.setup().columns },
			'lg',
		);

		if (!action) return;

		if (action.id == 'clear') this.grid.filters.clearFilters();
		else if (action.id == 'apply') {
			if (action.callbackResult) this.grid.filters.setFilters(action.callbackResult as CompositeFilterDescriptor);
		}
	}


	/**
	 * Build the count display under the header buttons. (e.g. "20 Mentors")
	 * If the grid is filtered then prefix it with that count (e.g. "7 of 20 Mentors") 
	 */
	updateCounts(): string {

		let counts = this.rows().length.toLocaleString();
		const filteredRows = this.grid.flatRows.filtered();

		if (filteredRows.length < this.rows().length) {
			counts = filteredRows.length.toLocaleString() + ' of ' + counts;
		}

		return counts;
	}


	onActionItemClick(action: GridAction<RowT>) {
		const rows = this.getSelectedRows();
		if (!action.isEnabled(rows)) return;
		action.execute(rows);
	}


	getSelectedRows(): RowT[] {
		return this.rows().filter(row => {
			const id = this.setup().getRowKey(row);
			return this.selectedRowIds().includes(id);
		});
	}


	getGroupCount(group: GroupResult) {
		return group.aggregates[group.field]?.count;
	}


	getColumnName(group: GroupResult) {
		const column = this.setup().columns.find(c => c.flatRowField == group.field);
		if (typeof column?.header == 'string') {
			return `${column.header}:`;
		}
		else return '';
	}


	formatGroupValue(group: GroupResult) {

		if (group.value === '' || group.value == null) return '(empty)';
		return group.value;
	}


	getHeaderTooltip(column: ColumnComponent) {
		const col = this.setup().columns.find(c => c.flatRowField == column.field);
		if (!col) return column.title;
		return col.headerTooltip ?? column.title;
	}


	getHeader(col: GridColumn<RowT, unknown>) {
		return col.header;
	}


	selectBy = (context: RowArgs) => {
		// return this.setup().getRowKey(context.dataItem);
		return context.dataItem.rowKey;
	}

}