import { Injectable } from '@angular/core';
import { UtilLogging } from './logging';

import { ArrayUtilities } from './arrays';
import { ClipboardUtilities } from './clipboard';
import { DateUtilities } from './date';
import { FullscreenUtilities } from './fullscreen';
import { MobileUtilities } from './mobile';
import { NPSUtilities } from './nps';
import { NumberUtilities } from './number';
import { ObjectUtilities } from './object';
import { PhoneUtilities } from './phone';
import { RecordUtilities } from './record';
import { RegexUtilities } from './regex';
import { TextUtilities } from './text';
import { MultipleValuesUtilities, SingleValueUtilities } from './value';
import { ZipUtilities } from './zip';

//
// Do not remove these unused imports
// TS 5.4 
import { groupBy } from './arrays/group-by';
import { ElapsedTimer } from './logging/elapsed-timer';
import { UrlUtilities } from './url';


@Injectable({ providedIn: 'root' })
export class UtilityService {

	/**
	 * Wraps setTimeout() in a Promise. Since an asynchronouse timer is used,
	 * await timeout(...) will flush the JavaScript event loop first. A value of
	 * zero (default) can be used to resolve right away but still flush existing events.
	 * 
	 * Use this to keep the browser UI responsive when performing an intensive synchronous activity
	 * such as manipulating data in a large loop. See https://www.youtube.com/watch?v=8aGhZQkoFbQ
	 * @param ms The number of milliseconds to wait for
	 * @returns Returns a promise that resolves after the provided number of milliseconds
	 */
	async setTimeout(ms = 0): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	public readonly array = ArrayUtilities;
	public readonly clipboard = ClipboardUtilities;
	public readonly date = DateUtilities;
	public readonly fullscreen = FullscreenUtilities;
	public readonly log = UtilLogging;
	public readonly mobile = MobileUtilities;
	public readonly nps = NPSUtilities;
	public readonly number = NumberUtilities;
	public readonly object = ObjectUtilities;
	public readonly phone = PhoneUtilities;
	public readonly record = RecordUtilities;
	public readonly regExp = RegexUtilities;
	public readonly text = TextUtilities;
	public readonly url = UrlUtilities;
	public readonly value = SingleValueUtilities;
	public readonly values = MultipleValuesUtilities;
	public readonly zip = ZipUtilities;

}