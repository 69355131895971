import { ElapsedTimer } from './elapsed-timer';


const errorLogger = buildErrorLogger('ERROR');
export const UtilLogging = {
	createElapsedTimer: (name: string, detail?: unknown) => { return new ElapsedTimer(name, detail); },
	fatalError: buildErrorLogger('FATAL'),
	errorMessage: (errMsg: string, extra?: unknown) => { errorLogger(errMsg, new Error(errMsg), extra); },

	info: (msg: string, extraData?: { extra: unknown, extraLogging: 'ExtraToConsole' | 'ExtraToConsoleAndSentry' }, category = 'info') => {

		console.info(msg, extraData?.extra);
	}
};


function buildErrorLogger(level: 'ERROR' | 'FATAL') {

	return (msg: string, err: Error, extra?: unknown) => {
		console.error(msg, err, extra);
	}
}
