import { AfterViewInit, Component, effect, inject, input, OnDestroy, OnInit, output } from '@angular/core';
import { WistiaService } from '../../../services/wistia/wistia.service';


@Component({
	selector: 'common-wistia-player',
	standalone: true,
	template: `<div id="wistia_player" class="wistia_embed wistia_async_{{video().wistiaId}}"></div>`,
	styles: ``,
})
export class CommonWistiaPlayerWidget implements OnInit, AfterViewInit, OnDestroy {
	public wistiaService = inject(WistiaService);

	public readonly video = input.required<{ wistiaId: string, furthestWatchedSecond?: number }>();
	public readonly videoWatchedVectorChanged = output<number[]>();



	// Track secondsWatchedVector updates and trigger event
	constructor() {
		effect(() => {
			const vector = this.wistiaService.secondsWatchedVector();
			if (vector) {
				this.videoWatchedVectorChanged.emit(vector); // Emit vector when it updates
			}
		});
	}



	async ngOnInit() {
		setTimeout(async () => {
			await this.loadVideo();
		}, 100);
	}


	async ngAfterViewInit() {
		await this.wistiaService.onNgAfterViewInit();
	}

	async ngOnDestroy() {
		await this.wistiaService.onNgOnDestroy();
	}


	async loadVideo() {
		const video = this.video();
		await this.wistiaService.replaceVideo(video.wistiaId, false);
		if (video.furthestWatchedSecond) await this.wistiaService.seekVideo(video.furthestWatchedSecond);
		await this.wistiaService.setWidth(396);
	}

}