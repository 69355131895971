import { UtilityService } from '@eforall/common';
import { GridSetup } from '@eforall/dashboard';
import { Staff } from '@app-interfaces';
import { getActions } from './get-actions';
import { getButtons } from './get-buttons';
import { getColumns } from './get-columns';


export function getSetup(util: UtilityService): GridSetup<Staff> {

	return {
		rowName: { singular: 'Function', plural: 'Functions' },
		getRowKey: row => '' + row.staffId,
		stateKey: 'functions-grid',
		actions: getActions(),
		buttons: getButtons(),
		columns: getColumns(util),
	}
}
