@if(frame.config()?.includeBreadcrumb){
<nav aria-label="breadcrumb">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
		@for(breadcrumb of breadcrumbList; track breadcrumb; let last = $last){
		@if(breadcrumb.link && !last){
		<li class="breadcrumb-item">
			<a [routerLink]="breadcrumb.link">{{ breadcrumb.title }}</a>
		</li>
		}
		@if(last || !breadcrumb.link){
		<li class="breadcrumb-item active" aria-current="page">{{ breadcrumb.title }}</li>
		}
		}
	</ol>
</nav>
}