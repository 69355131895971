import { GridColumn } from "../interfaces";

/**
 * Generate an index-based unique field name for each column.
 */
export function setFlatRowFields<RowT extends { updatedUTC: number }>(columns: GridColumn<RowT, unknown>[]) {
	let index = 0;
	for (const col of columns ?? []) {
		col.flatRowField = 'col' + index;
		index++;
	}
}