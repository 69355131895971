import { Routes } from '@angular/router';
import { DashboardError404Page, DashboardFrame } from '@eforall/dashboard';
import { AppContentPage } from './pages/app-content/app-content.page';
import { HomePage } from './pages/home/home.page';
import { FaqsPage, HowTosPage, PoliciesPage } from './pages/knowledge';
import { CalendarPage, DepartmentsPage, FunctionsPage, TeamsPage } from './pages/org';
import { SoftwarePage, SoftwareUsersPage } from './pages/software';
import { JobRolesPage, JobTracksPage, MessagesPage, PhotosPage, StaffPage } from './pages/staff';
import { TechExamplePage } from './pages/tech/examples/tech-examples.page';
import { RoadmapExamplePage } from './pages/tech/roadmap/roadmap-example.page';
import { Page2Page } from './pages/tech/page-2/page-2.page';
import { Page3Page } from './pages/tech/page-3/page-3.page';


export const routes: Routes = [
	{
		path: '',
		component: DashboardFrame,
		children: [
			{
				path: '',
				component: AppContentPage,
				children: [

					{ path: '', component: HomePage },

					//
					// Staff
					//
					{ path: 'messages', component: MessagesPage },
					{ path: 'staff', component: StaffPage },
					{ path: 'job-tracks', component: JobTracksPage },
					{ path: 'job-roles', component: JobRolesPage },
					{ path: 'photos', component: PhotosPage },

					//
					// Org
					//
					{ path: 'calendar', component: CalendarPage },
					{ path: 'departments', component: DepartmentsPage },
					{ path: 'functions', component: FunctionsPage },
					{ path: 'teams', component: TeamsPage },

					//
					// Knowledge
					//
					{ path: 'policies', component: PoliciesPage },
					{ path: 'how-tos', component: HowTosPage },
					{ path: 'faqs', component: FaqsPage },

					//
					// Software
					//
					{ path: 'software', component: SoftwarePage },
					{ path: 'software-users', component: SoftwareUsersPage },

					//
					// Other
					//
					{ path: 'page-2', component: Page2Page },
					{ path: 'page-3', component: Page3Page },
					{ path: 'example', component: TechExamplePage },
					{ path: 'roadmap', component: RoadmapExamplePage },
				]
			},


			//
			// Page not found
			//
			{ path: '**', component: DashboardError404Page },
		]
	},

];
