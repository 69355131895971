import { Component } from '@angular/core';
import { DashboardScrollableContentWidget } from '@eforall/dashboard';

@Component({
	selector: 'page-2-page',
	standalone: true,
	imports: [DashboardScrollableContentWidget],
	templateUrl: './page-2.page.html'
})

export class Page2Page {


}