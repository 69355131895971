import { Injectable, inject, signal } from '@angular/core';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthService } from '../auth';
import { SpinnerService } from '../spinner';
import { UtilityService } from '../util';


export interface ErrorDetails {
	error: boolean;
	activity?: string;
	name?: string,
	message?: string;
	stack?: string;
	path?: string,
	payload?: any,
	appDeviceUuid?: string,
}

const NO_ERROR: ErrorDetails = { error: false };

@Injectable({ providedIn: 'root' })
export class ErrorService {

	private analytics = inject(AnalyticsService);
	private auth = inject(AuthService);
	private util = inject(UtilityService);
	private spinnerService = inject(SpinnerService);

	public readonly details = signal<ErrorDetails>(NO_ERROR);

	public snapshot = NO_ERROR;


	setError(
		activity: string,
		error: Error | string,
		errorName: string | undefined,
		path: string,
		payload: any,
		appDeviceUuid?: string,
	) {

		// Sentry.setTag("e4a_error_page", "yes");
		// Sentry.showReportDialog();

		if (error instanceof Error) {
			this.util.log.fatalError('Red Error Page Shown to User!', error);
		}
		else {
			if (error !== 'Error: No Verified Email Address') {
				this.util.log.fatalError('Red Error Page Shown to User', new Error(error));
			}
		}


		const d: ErrorDetails = {
			error: true,
			activity,
			name: '',
			message: '',
			stack: '',
			path,
			payload,
			appDeviceUuid,
		};

		if (error instanceof Error) {
			d.name = errorName || error.name || 'Error';
			d.message = error.message || `Unexpected Error. See the console log`;
			d.stack = error.stack || '';

			this.analytics.logError(error);
		}
		else {
			d.name = errorName || 'Error';
			d.message = error || `Unexpected Error! See the console log`;

			this.analytics.logException({ ...d, description: d.message, fatal: true });
		}

		this.details.set(d);
		console.log(this.details());
		this.snapshot = d;


		this.spinnerService.forceOff();
	}

	clearError() {
		this.details.set(NO_ERROR);
		this.snapshot = NO_ERROR;
	}
}