import { Component, HostListener, inject } from '@angular/core';
import { CommonSpinnerWidget, SpinnerService } from '@eforall/common';


@Component({
	selector: 'dashboard-spinner',
	imports: [CommonSpinnerWidget],
	standalone: true,
	templateUrl: './dashboard-spinner.widget.html',
	styleUrl: './dashboard-spinner.widget.scss'
})


export class DashboardSpinnerWidget {

	public spinnerService = inject(SpinnerService);

	@HostListener('click', ['$event'])
	backClick(e: Event) {
		console.log(e);
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

}


