<div class="dashboard-error-part">
	@if(errorService.details().error){

		@if(errorService.details().message === 'Error: No Verified Email Address'){
			<dashboard-error-unverified-email-part></dashboard-error-unverified-email-part>
		}
		@else(){
			<dashboard-error-generic-data-part></dashboard-error-generic-data-part>
		}
	}
</div>