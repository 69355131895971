import { Injectable, signal } from '@angular/core';
import { DashboardFrameConfig } from './dashboard-frame-config';


@Injectable({ providedIn: 'root' })
export class DashboardFrameService {

	public config = signal<DashboardFrameConfig | undefined>(undefined);


	public initialize(config: DashboardFrameConfig) {

		console.log(`DashboardFrameService.initialize()`, config);

		if (this.config()) return;	// Ignore duplicate calls
		if (!config) throw new Error(`DashboardFrameService.initialize() called with an undefined parameter.`);

		this.config.set(config);
	}


}