import { DashboardSectionMenu } from '@eforall/dashboard';


export const STAFF_MENU: DashboardSectionMenu = {
	label: 'Staff',
	items: [
		{
			label: 'Staff',
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-person',
			routerLink: '/staff'
		},
		{
			label: 'Photos',
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-camera',
			routerLink: '/photos'
		},
		{
			label: 'Job Tracks',
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-square-j',
			routerLink: '/job-tracks'
		},
		{
			label: 'Job Roles',
			kind: 'menuItem',
			iconStyle: 'fa-regular',
			icon: 'fa-j',
			routerLink: '/job-roles'
		},
	]
}