import { Message } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<Message, unknown>[] = [
		buildColumn(util, 'Id', row => row.messageId, { header: 'messageId' }),
		buildColumn(util, 'Text', row => row.status, { width: 80, header: 'Status' }),
		buildColumn(util, 'Text', row => row.category, { header: 'Category' }),
		buildColumn(util, 'Text', row => row.title, { width: 200, header: 'Title' }),

		buildColumn(util, 'Date', row => util.date.fromUTC(row.startUTC), { hidden: true, header: 'Start' }),
		buildColumn(util, 'Date', row => util.date.fromUTC(row.stopUTC), { header: 'Stop' }),

		buildColumn(util, 'Text', row => row.target, { header: 'Target' }),
		buildColumn(util, 'Number', row => row.responses, { header: 'Responses' }),
		buildColumn(util, 'Number', row => row.recipients, { hidden: true, header: 'Recipients' }),
		buildColumn(util, 'Percent', row => row.recipients > 0 ? row.responses / row.recipients * 100 : 0, { header: 'Progress' }),

		buildColumn(util, 'Text', row => row.actionText, { width: 120, hidden: true, header: 'Action Text' }),
		buildColumn(util, 'Text', row => row.deferText, { width: 120, hidden: true, header: 'Defer Text' }),

		buildColumn(util, 'Id', row => row.createdByStaffId, { header: 'createdByStaffId' }),
		buildColumn(util, 'FullName', row => row.createdBy.fullName, { hidden: true, header: 'Created By' }),
		buildColumn(util, 'Date', row => util.date.fromUTC(row.createdUTC), { hidden: true, header: 'Created' }),
		buildColumn(util, 'Date', row => util.date.fromUTC(row.updatedUTC), { hidden: true, header: 'Updated' }),
	];

	return columns;

}