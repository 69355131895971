import { AppName } from './env-types';

/**
 * Get the HTTP port that the localhost server will listen on.
 * 
 * IMPORTANT: The mapping of ports to app names must be the
 * same as the mapping in func-common/env 
 */

export function getLocalPort(appName: AppName) {


	const ports: Record<AppName, number> = {
		'Admin': 5001,
		'Data': 5002,
		'Programs': 5003,
		'Public': 5004,
		'Staff': 5005,
		'Training': 5006,
	};

	return ports[appName];
}