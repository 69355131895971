import { Component, computed, inject, input, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterExpression, FilterModule, } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DialogAction } from '../../../../services';
import { DialogFrame } from '../../../../services/dialog/ui/frame/dialog.frame';
import { GridColumn } from '../interfaces';


interface FilterDialogData<RowT extends { updatedUTC: number }> {
	filters: CompositeFilterDescriptor,
	columns: GridColumn<RowT, unknown>[],
}


@Component({
	selector: 'grid-filter-dialog',
	standalone: true,
	imports: [DialogFrame, FilterModule],
	templateUrl: './filter.dialog.html',
})
export class GridFilterDialog<RowT extends { updatedUTC: number }> {
	activeModal = inject(NgbActiveModal);

	public readonly data = input<FilterDialogData<RowT>>({ filters: { filters: Array(0), logic: 'and' }, columns: [] });
	public readonly originalFilters = computed(() => this.data().filters);
	private readonly _updatedFilters = signal<CompositeFilterDescriptor | undefined>(undefined);
	public readonly updatedFilters = computed(() => this._updatedFilters() ?? this.originalFilters());


	public readonly filterExpressions = computed<FilterExpression[]>(() => {
		return this.mapColumnsToFilterExpressions(this.data().columns);
	});


	private applyButtonCssClass = computed(() => this._updatedFilters() ? 'btn-green' : '');

	
	public actions = computed<DialogAction[]>(() => {
		return [
			{
				id: 'apply',
				cssClass: this.applyButtonCssClass(),
				enabled: true,
				visible: true,
				label: 'Apply',
				linkType: "callback",
				callback: async () => (this.updatedFilters()),
				willCloseDialog: true,
			},
			{
				id: 'clear',
				cssClass: 'btn-danger',
				enabled: true,
				visible: true,
				label: 'Clear',
				linkType: "no-action",
				willCloseDialog: true,
			}
		];
	});


	mapColumnsToFilterExpressions(columns: GridColumn<RowT, unknown>[]): FilterExpression[] {

		const expressions: FilterExpression[] = columns
			.map(column => {
				const e: FilterExpression = {
					field: column.flatRowField,
					title: column.header as string,
					editor: this.getFilterEditor(column),
				};

				return e;
			})
			.filter(e => !!e.editor);	// Unfilterable columns will have editor set to undefined

		return expressions;
	}


	/**
	 * This function overcomes inconsistencies with Kendo filter types. The KendoColumn used filter
	 * types of 'text' and 'numeric' but the FilterExpression interface uses 'string' and 'number'
	 */
	getFilterEditor(column: GridColumn<RowT, unknown>) {

		const filterType = column.filterType;

		if (filterType == 'numeric') return 'number';
		if (filterType == 'boolean') return 'boolean';
		if (filterType == 'date') return 'date';

		return 'string';
	}


	onFiltersChange(filters: CompositeFilterDescriptor | undefined) {
		if (filters) {
			this._updatedFilters.set(filters);
		}
	}

}