import { AfterViewInit, Component, ViewChild, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonIconTextWidget } from '@eforall/common';
import { NgbAccordionDirective, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardMenuSet } from '../../dashboard-frame-config';
import { DashboardMenuItemPart } from '../menu-item/dashboard-menu-item.part';


@Component({
	selector: 'dashboard-menu-accordion-part',
	standalone: true,
	imports: [NgbAccordionModule, CommonIconTextWidget, DashboardMenuItemPart],
	templateUrl: './dashboard-menu-accordion.part.html',
	styleUrls: ['./dashboard-menu-accordion.part.scss'],
})
export class DashboardMenuAccordionPart implements AfterViewInit {
	public readonly set = input.required<DashboardMenuSet>();
	private router = inject(Router);
	@ViewChild('accordion') accordionComponent!: NgbAccordionDirective;


	ngAfterViewInit() {
		const currentUrl = this.router.url;

		if (this.set().menuItems.find(item => (item.routerLink && item.routerLink.includes(currentUrl)) || (item.url && item.url.includes(currentUrl)))) {
			this.accordionComponent.expand(this.set().label);
		}
		else {
			this.accordionComponent.collapse(this.set().label);
		}
	}
}