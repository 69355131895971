import { Injectable, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { SpinnerService } from '../spinner';
import { UtilityService } from '../util';
import { filter } from 'rxjs';
import { BeforeInstallPromptEvent } from './before-install-prompt-event'


@Injectable({ providedIn: 'root' })
export class PwaService {

	private swUpdate = inject(SwUpdate);
	private spinner = inject(SpinnerService);
	private util = inject(UtilityService);

	private promptEvent: BeforeInstallPromptEvent | undefined;


	constructor(
	) {

		window.addEventListener('beforeinstallprompt', (event) => {
			event.preventDefault();
			this.util.log.info('EVENT beforeinstallprompt fired in PwaService');
			this.promptEvent = event as BeforeInstallPromptEvent;
		});

		this.swUpdate.versionUpdates
			.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
			.subscribe(event => {
				if (this.askUserToUpdate()) window.location.reload();
			});
	}


	private askUserToUpdate() {
		//TODO: Launch a dialog and let the user know an update is available and ask them if they would like to install it
		return true;
	}


	/**
	 * Determine if the app can be installed. Currently, this only works on chrome and android webview.
	 */
	get canBeInstalled() {
		return !!this.promptEvent;
	}


	/**
	 * If the app can be installed, ask the user to install it. If they accept then
	 * the app will be installed and this function will return true. Otherwise it
	 * will return false.
	 */
	async promptToInstall(): Promise<boolean> {
		if (!this.promptEvent) return false;
		const INSTALL_DESC = 'install-desc';
		try {
			this.spinner.addSpinner(INSTALL_DESC, INSTALL_DESC);
			await this.promptEvent.prompt();
			const choice = await this.promptEvent.userChoice;
			return choice?.outcome == 'accepted';
		}

		finally {
			this.spinner.removeSpinner(INSTALL_DESC);
		}
	}
}