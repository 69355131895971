import { Component, input } from '@angular/core';


@Component({
	selector: 'dashboard-logo-part',
	standalone: true,
	templateUrl: './dashboard-logo.part.html',
	styleUrls: ['./dashboard-logo.part.scss'],
})
export class DashboardLogoPart {

	public readonly logo = input<'full' | 'small'>('full');

}