<div class="common-smiley-rating">
	<button class="btn btn-flat p-2" [disabled]="disabled()"
		[ngClass]="{'active': rating() === 1, 'focus': rating() !== 1 && isFocused(1)}" aria-label="Very Bad"
		(click)="setRating(1)" (focus)="setFocus(1)" (blur)="clearFocus()">
		<i [ngClass]="{'fa-solid': rating() === 1, 'color-eforall-blue': rating() === 1}"
			class="color-darkgray fa-regular fa-face-frown-open"></i>
	</button>
	<button class="btn btn-flat p-2" [disabled]="disabled()"
		[ngClass]="{'active': rating() === 2, 'focus': rating() !== 2 && isFocused(2)}" aria-label="Bad"
		(click)="setRating(2)" (focus)="setFocus(2)" (blur)="clearFocus()">
		<i [ngClass]="{'fa-solid': rating() === 2, 'color-eforall-blue': rating() === 2}"
			class="color-darkgray fa-regular fa-face-frown-slight"></i>
	</button>
	<button class="btn btn-flat p-2" [disabled]="disabled()"
		[ngClass]="{'active': rating() === 3, 'focus': rating() !== 3 && isFocused(3)}" aria-label="Neutral"
		(click)="setRating(3)" (focus)="setFocus(3)" (blur)="clearFocus()">
		<i [ngClass]="{'fa-solid': rating() === 3, 'color-eforall-blue': rating() === 3}"
			class="color-darkgray fa-regular fa-meh"></i>
	</button>
	<button class="btn btn-flat p-2" [disabled]="disabled()"
		[ngClass]="{'active': rating() === 4, 'focus': rating() !== 4 && isFocused(4)}" aria-label="Good"
		(click)="setRating(4)" (focus)="setFocus(4)" (blur)="clearFocus()">
		<i [ngClass]="{'fa-solid': rating() === 4, 'color-eforall-blue': rating() === 4}"
			class="color-darkgray fa-regular fa-smile"></i>
	</button>
	<button class="btn btn-flat p-2" [disabled]="disabled()"
		[ngClass]="{'active': rating() === 5, 'focus': rating() !== 5 && isFocused(5)}" aria-label="Very Good"
		(click)="setRating(5)" (focus)="setFocus(5)" (blur)="clearFocus()">
		<i [ngClass]="{'fa-solid': rating() === 5, 'color-eforall-blue': rating() === 5}"
			class="color-darkgray fa-regular fa-face-grin-wide"></i>
	</button>
</div>