import { Injectable } from '@angular/core';
import * as I from '@interfaces';
import { 
	AuthService,
	EnvironmentService,
	ErrorService,
	FuncServiceBase,
	SpinnerService,
	UrlService,
	UtilityService,
} from '@eforall/common';

//
//  This class is generated by generate-client-shims.mjs which is called
//  during the build. DON'T CHANGE IT MANUALLY.
//

@Injectable({ providedIn: 'root' })
export class FuncService extends FuncServiceBase {

	constructor(
		env: EnvironmentService,
		auth: AuthService,
		errorService: ErrorService,
		spinner: SpinnerService,
		url: UrlService,
		util: UtilityService,
) {
		super(env, auth, errorService, spinner, url, util);
	}

	public test = {
		getDirectory: this._callNoPayload<void>('test.getDirectory'),
	};

	public getData = this._callNoPayload<I.FuncData>('getData');
}