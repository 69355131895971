import { Component, OnInit, computed, effect, inject, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonTextField } from '../field';

@Component({
	selector: 'common-text-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-text-field.widget.html',
})
export class CommonTextFieldWidget extends CommonFieldWidgetBase<string, CommonTextField> implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonTextField>();

	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: `ES Required field`,
			minLength: `ES Text too short. Min of ${config.min} characters.`,
			maxLength: `ES Text too long. Max of ${config.max} characters.`,
		} : {
			missingRequired: `Required field`,
			minLength: `Text too short. Min of ${config.min} characters.`,
			maxLength: `Text too long. Max of ${config.max} characters.`,
		};

	});


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		if (!this.hasFocus()) return this.render(value);
		return value;
	});


	public readonly almostMaxLength = computed<{ text: string, error: boolean }>(() => {
		const config = this.field().config();
		const value = this.field().pendingValue() ?? this.field().actualValue();

		const calculatedMax = config.max && config.max > 5 ? config.max - 5 : config.max || 0;
		if (config.max && value.length && value.length > calculatedMax) {
			return { text: `${value.length} / ${config.max}`, error: value.length > config.max };
		}
		else return { text: config.multiLine && config.max ? `${value.length} / ${config.max}` : '', error: false };
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: string) {
		this.field().pendingValue.set(value);
	}

	public async onBlur() {
		await this.blur(this.field());
	}


	protected override cleanValue(value: string) {
		return this.util.text.fixCase(value, 'sentence-case');
	}

	protected override render(value: string) {
		return this.util.text.fixCase(value, 'sentence-case');
	}


	protected validate(): string {

		const value = (this.getValue(this.field())).trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.min) return labels.missingRequired;
		if (value.length < config.min) return labels.minLength;
		if (config.max && value.length > config.max) return labels.maxLength;

		return '';
	}
}