import { ComponentType } from '@angular/cdk/overlay/index';
import { ComponentRef, Injectable, InputSignal, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialog, DialogAction, MessageDialog } from './';
import { ConfirmDialogData } from './ui/confirm/confirm.dialog';
import { MessageDialogData } from './ui/message/message.dialog';


/**
 * Component to render within the body of a dialog.
 */
export interface DialogComponent<C extends { readonly data: InputSignal<D> }, D> extends ComponentType<C> {
}


@Injectable({ providedIn: 'root' })
export class DialogService {

	private ngbModalService = inject(NgbModal);


	/**
	 *  Create and show a message dialog with one button to close it.
	 */
	public async showMessage(message: string, buttonText?: string, bullets?: string[]): Promise<DialogAction | undefined> {
		return await this.openDialog<MessageDialog, MessageDialogData>(MessageDialog, { message, buttonText, bullets: bullets || [] });
	}


	/**
	 *  Create and show a confirm dialog asking the user to select yes or no.
	 */
	public async confirm(message: string, yesButtonText?: string, noButtonText?: string, bullets?: string[]): Promise<boolean> {

		const result: DialogAction | undefined = await this.openDialog<ConfirmDialog, ConfirmDialogData>(ConfirmDialog, { message, yesButtonText, noButtonText, bullets: bullets || [] });

		return result?.id == 'yes';

	}

	/**
	 *  Create and show a custom dialog.
	 */
	public async showCustom<C extends { readonly data: InputSignal<D> }, D>(component: DialogComponent<C, D>, data: D, size?: 'sm' | 'lg' | 'xl'): Promise<DialogAction | undefined> {
		return await this.openDialog(component, data);
	}



	private async openDialog<C extends { readonly data: InputSignal<D> }, D>(component: DialogComponent<C, D>, data: D, size?: 'sm' | 'lg' | 'xl'): Promise<DialogAction | undefined> {

		const ref = this.ngbModalService.open(component, { centered: true, size, windowClass: 'eforall-modal' });

		/**
		 * This is a hack to get the ComponentRef because NgbModalRef does not expose it.
		 * See https://github.com/ng-bootstrap/ng-bootstrap/issues/4688
		 */
		const componentRef: ComponentRef<C> = ref['_contentRef'].componentRef;
		componentRef.setInput('data', data);

		const result: DialogAction | undefined = await ref.result;

		return result;
	}




	/**
	 * Close any open dialogs.
	 */
	closeAll() {
		this.ngbModalService.dismissAll();
	}
}
