import { AppData } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { FuncData } from '@interfaces';
import { buildKnowledgeData } from './build-knowledge-data';
import { buildStaffData } from './build-staff-data';



/**
 * Convert func data into structures that can more
 * easily be used in the app (e.g. compound objects)
  */
export function processData(data: FuncData, util: UtilityService): AppData {


	const {
		departmentById,
		departments,
		jobRoles,
		jobTracks,
		locations,
		pronouns,
		staffById,
		staff,
	} = buildStaffData(data, util);


	const {
		messages,
		software,
	} = buildKnowledgeData(data, staffById, departmentById);

	
	//
	// Return all the data
	//
	return {
		asOfUtc: data.asOfUtc,

		//
		// Staff Data
		//
		departments,
		jobRoles,
		jobTracks,
		locations,
		pronouns,
		staff,

		//
		// Knowledge Data
		//
		messages,
		software,
	};
}




