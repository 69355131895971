<div class="d-flex flex-row justify-content-between align-items-center">
	<div>{{rating().text}}</div>
	<div class="d-flex flex-row">
		<button class="btn btn-flat p-1" aria-label="Thumbs Up" (click)="toggleRating('up')">
			<i [ngClass]="{
				'fa-regular': rating().rating !== 1,
				'fa-solid': rating().rating === 1,
				'color-rating-5': rating().rating === 1,
			}" class="fs-4 color-gray fa-thumbs-up"></i>
		</button>
		<button class="btn btn-flat p-1" aria-label="Thumbs Down" (click)="toggleRating('down')">
			<i [ngClass]="{
				'fa-regular': rating().rating !== -1,
				'fa-solid': rating().rating === -1,
				'color-rating-1': rating().rating === -1,
			}" class="fs-4 color-gray fa-thumbs-down"></i>
		</button>
	</div>
</div>