
export const SingleValueUtilities = {

	/**
	 * Returns whether the provided value is primitive. Use this to distinguish objects and arrays
	 * from javascript primitive types (undefined, null, number, bigint, string, boolean).
	 * See: https://developer.mozilla.org/en-US/docs/Glossary/Primitive
	 */
	isPrimitive: (value: unknown) => Object(value) !== value,

};


export const MultipleValuesUtilities = {

	/**
	 * Compare any two values (including arrays and objects) and determine if they are the same.
	 */
	areSame: (v1: any, v2: any) => {

		//
		// Quick out when values are arrays with different length
		//
		if ((Array.isArray(v1) || Array.isArray(v2)) && v1?.length !== v2?.length) {
			return false;
		}

		//
		// Quick out when values are primitives
		//
		else if (SingleValueUtilities.isPrimitive(v1) || SingleValueUtilities.isPrimitive(v2)) {
			return v1 == v2;
		}

		return false;
	}

};