import { UtilityService } from '@eforall/common';

export type CellClass
	= 'grid-cell-blue-text'
	| 'grid-cell-gray-text'
	| 'grid-cell-green-bg'
	| 'grid-cell-green-text'
	| 'grid-cell-icon-center'
	| 'grid-cell-orange-bg'
	| 'grid-cell-red-bg'
	| 'grid-cell-red-text'
	| 'grid-cell-right-align'
	| 'grid-cell-strikethrough'
	| 'grid-cell-yellow-bg'
	;

export type RendererKind = 'text' | 'numeric' | 'boolean' | 'date';

export type ColumnFormatRenderer<RowT extends { updatedUTC: number }, ValueT> = (row: RowT, value: ValueT, util: UtilityService) => string | undefined;
export type ColumnTooltipRenderer<RowT extends { updatedUTC: number }, ValueT> = (row: RowT, value: ValueT, util: UtilityService) => string | undefined;
export type ColumnIconClassesRenderer<RowT extends { updatedUTC: number }, ValueT> = (row: RowT, value: ValueT) => string | undefined;
export type ColumnCellClassesRenderer<RowT extends { updatedUTC: number }, ValueT> = (row: RowT, value: ValueT) => CellClass[];
export type ColumnRowClassesRenderer<RowT extends { updatedUTC: number }> = (row: RowT) => CellClass[];


export interface GridColumnRenderer<RowT extends { updatedUTC: number }, ValueT> {
	kind: RendererKind,
	format: ColumnFormatRenderer<RowT, ValueT>,
	tooltip: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses: ColumnCellClassesRenderer<RowT, ValueT>,
}


/**
 * Renderer for type Number
 */
export function createNumericRenderer<RowT extends { updatedUTC: number }, ValueT extends number | undefined>(
	format?: ColumnFormatRenderer<RowT, ValueT>,
	tooltip?: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses?: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses?: ColumnCellClassesRenderer<RowT, ValueT>,
): GridColumnRenderer<RowT, ValueT> {
	if (!format) format = (row: RowT, value: ValueT) => (value ?? '').toLocaleString();
	if (!tooltip) tooltip = (row: RowT, value: ValueT) => undefined;
	if (!iconClasses) iconClasses = (row: RowT, value: ValueT) => undefined;
	if (!cellClasses) cellClasses = (row: RowT, value: ValueT) => ['grid-cell-right-align'];

	return { kind: 'numeric', format, tooltip, iconClasses, cellClasses };
}

/**
 * Renderer for text
 */
export function createTextRenderer<RowT extends { updatedUTC: number }, ValueT extends string | undefined>(
	format?: ColumnFormatRenderer<RowT, ValueT>,
	tooltip?: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses?: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses?: ColumnCellClassesRenderer<RowT, ValueT>,
): GridColumnRenderer<RowT, ValueT> {
	if (!format) format = (row: RowT, value: ValueT) => value ?? '';
	if (!tooltip) tooltip = (row: RowT, value: ValueT) => undefined;
	if (!iconClasses) iconClasses = (row: RowT, value: ValueT) => undefined;
	if (!cellClasses) cellClasses = (row: RowT, value: ValueT) => [];

	return { kind: 'text', format, tooltip, iconClasses, cellClasses };
}


/**
 * Renderer for Boolean
 */
export function createBooleanRenderer<RowT extends { updatedUTC: number }, ValueT extends boolean | undefined>(
	display: [string, string] = ['Yes', 'No'],
	tooltip?: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses?: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses?: ColumnCellClassesRenderer<RowT, ValueT>,
): GridColumnRenderer<RowT, ValueT> {
	const format = (row: RowT, value: ValueT) => value ? display[0] : display[1];
	if (!tooltip) tooltip = (row: RowT, value: ValueT) => undefined;
	if (!iconClasses) iconClasses = (row: RowT, value: ValueT) => undefined;
	if (!cellClasses) cellClasses = (row: RowT, value: ValueT) => [];

	return { kind: 'boolean', format, tooltip, iconClasses, cellClasses };
}


/**
 * Renderer for Date based on display
 */
export function createDateTimeRenderer<RowT extends { updatedUTC: number }, ValueT extends Date | undefined>(
	util: UtilityService,
	display: 'date' | 'time' | 'date+time',
	tooltip?: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses?: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses?: ColumnCellClassesRenderer<RowT, ValueT>,
): GridColumnRenderer<RowT, ValueT> {
	const format = (row: RowT, value: ValueT) => {
		if (value) {
			if (display == 'date') return util.date.formatDate(value, 'MMM D, YYYY', 'en-US');
			if (display == 'time') return util.date.formatTime(value, 'en-US');
			return util.date.formatDateAndTime(value, 'MMM D, YYYY', 'en-US');
		}
		return '';
	};
	if (!tooltip) tooltip = (row: RowT, value: ValueT) => undefined;
	if (!iconClasses) iconClasses = (row: RowT, value: ValueT) => undefined;
	if (!cellClasses) cellClasses = (row: RowT, value: ValueT) => [];

	return { kind: 'date', format, tooltip, iconClasses, cellClasses };
}


/**
 * Renderer for UTC based on display
 */
export function createDateTimeUTCRenderer<RowT extends { updatedUTC: number }, ValueT extends number | undefined>(
	util: UtilityService,
	display: 'dateUTC' | 'timeUTC' | 'dateUTC+timeUTC',
	tooltip?: ColumnTooltipRenderer<RowT, ValueT>,
	iconClasses?: ColumnIconClassesRenderer<RowT, ValueT>,
	cellClasses?: ColumnCellClassesRenderer<RowT, ValueT>,
): GridColumnRenderer<RowT, ValueT> {
	const format = (row: RowT, value: ValueT) => {
		if (value) {
			if (display == 'dateUTC') return util.date.formatUTC(value, 'MMM D, YYYY', 'No Time', 'en-US');
			if (display == 'timeUTC') return util.date.formatUTC(value, 'No Date', 'H:MM AM EST', 'en-US');
			return util.date.formatUTC(value, 'MMM D, YYYY', 'H:MM AM EST', 'en-US');
		}
		return '';
	};
	if (!tooltip) tooltip = (row: RowT, value: ValueT) => undefined;
	if (!iconClasses) iconClasses = (row: RowT, value: ValueT) => undefined;
	if (!cellClasses) cellClasses = (row: RowT, value: ValueT) => [];

	return { kind: 'date', format, tooltip, iconClasses, cellClasses };
}