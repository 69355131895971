import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbActiveOffcanvas, } from '@ng-bootstrap/ng-bootstrap';
import { DashboardFrameService } from '../../dashboard-frame.service';
import { DashboardLogoPart } from '../../logo/dashboard-logo.part';
import { DashboardMenuAccordionPart } from '../menu-accordion/dashboard-menu-accordion.part';
import { DashboardMenuItemPart } from '../menu-item/dashboard-menu-item.part';


@Component({
	selector: 'dashboard-menu-offcanvas-part',
	standalone: true,
	imports: [DashboardMenuItemPart, DashboardMenuAccordionPart, DashboardLogoPart, RouterModule],
	templateUrl: './dashboard-menu-offcanvas.part.html',
	styleUrl: './dashboard-menu-offcanvas.part.scss'
})
export class DashboardMenuOffcanvasPart implements OnInit {
	activeOffcanvas = inject(NgbActiveOffcanvas);
	frame = inject(DashboardFrameService);

	public router = inject(Router);
	
	ngOnInit() {
		this.router.events
			.subscribe((event) => {
				this.activeOffcanvas.dismiss(); // Close the off-canvas
			});
	}

}