import { Component } from '@angular/core';


@Component({
	selector: 'common-spinner',
	standalone: true,
	templateUrl: './common-spinner.widget.html',
	styleUrls: ['./common-spinner.widget.scss']
})
export class CommonSpinnerWidget {
}