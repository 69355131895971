import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogAction } from '../dialog-action';
import { DialogFrame } from '../frame/dialog.frame';

export interface ConfirmDialogData {
	message: string,
	yesButtonText?: string,
	noButtonText?: string,
	bullets?: string[],
}


@Component({
	selector: 'confirm-dialog',
	standalone: true,
	imports: [DialogFrame, CommonModule],
	templateUrl: './confirm.dialog.html',
})

export class ConfirmDialog {

	activeModal = inject(NgbActiveModal);

	public readonly data = input<ConfirmDialogData>({ message: '' });

	public actions = computed<DialogAction[]>(() => {

		return [
			{
				id: 'yes',
				enabled: true,
				visible: true,
				label: this.data().yesButtonText ?? 'Yes',
				linkType: "no-action",
				cssClass: 'btn-success',
				willCloseDialog: true,
			},
			{
				id: 'no',
				enabled: true,
				visible: true,
				label: this.data().noButtonText ?? 'No',
				cssClass: 'btn-danger',
				linkType: "no-action",
				willCloseDialog: true,
			}
		];
	});
}
