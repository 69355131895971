import { Component, computed, inject } from '@angular/core';
import { AuthService, CommonButtonRowWidget, EnvironmentService } from '@eforall/common';
import { DashboardScrollableContentWidget } from '@eforall/dashboard';
import { AppService, FuncService } from '../../services';


@Component({
	selector: 'home-page',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		DashboardScrollableContentWidget,
	],
	templateUrl: './home.page.html'
})
export class HomePage {

	public env = inject(EnvironmentService);
	public auth = inject(AuthService);
	private func = inject(FuncService);
	public app = inject(AppService);

	async getDirectory() {
		await this.func.test.getDirectory();
	}

}
