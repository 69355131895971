import { CommonModule } from '@angular/common';
import { Component, inject, input, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { DialogAction } from '../dialog-action';


@Component({
	selector: 'dialog-frame',
	standalone: true,
	imports: [CommonModule, CommonButtonRowWidget],
	templateUrl: './dialog.frame.html',
})

export class DialogFrame {

	private readonly router = inject(Router);

	public readonly icon = input<string>('fa-info-circle');
	public readonly header = input<string>('Header');
	public readonly actions = input<DialogAction[]>([]);

	public readonly actionClick = output<DialogAction<unknown>>();
	public readonly closed = output<DialogAction<unknown> | undefined>();

	public readonly handlingClick = signal(false);


	async click(action: DialogAction<unknown>) {
		
		this.handlingClick.set(true);

		//
		// perform the action
		//
		if (action.linkType == 'routerLink') {
			this.router.navigate([action.url]);
		}

		else if (action.linkType == 'href') {
			//
			// Nothing to do. The a[href] will handle
			// the navigation
			//
		}
			
		else if (action.linkType == 'callback') {
			action.callbackResult = action.callback ? (await action.callback()) ?? action.callbackResult : action.callbackResult;
		}
			
		else if (action.linkType == 'actionClick-event') {
			this.actionClick.emit(action);
		}


		//
		// close the dialog
		//
		if (action.willCloseDialog) {
			this.closed.emit(action);
		}

		this.handlingClick.set(false);
	}
}
