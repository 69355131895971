// Angular Import
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { DashboardMenuItem, DashboardMenuSet, DashboardSectionMenu } from '../dashboard-frame-config';
import { DashboardFrameService } from '../dashboard-frame.service';


interface BreadcrumbItem {
	title: string;
	link?: string;
	type: string;
}

@Component({
	selector: 'dashboard-breadcrumb-part',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './dashboard-breadcrumb.part.html',
	styleUrls: ['./dashboard-breadcrumb.part.scss']
})
export class DashboardBreadcrumbPart implements OnInit {

	private router = inject(Router);
	private title = inject(Title);
	public frame = inject(DashboardFrameService);

	navigations: DashboardSectionMenu[] = [];
	breadcrumbList: BreadcrumbItem[] = [];

	ngOnInit(): void {
		this.navigations = this.frame.config()?.menu || [];
		this.setBreadcrumb();
	}

	setBreadcrumb(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				const activeLink = event.url;
				const breadcrumbList = this.filterNavigation(this.navigations, activeLink);
				this.breadcrumbList = breadcrumbList;

				const appName = this.frame.config()?.appName || '';
				const t = breadcrumbList[breadcrumbList.length - 1]?.title || 'Home';
				this.title.setTitle(`${appName} - ${t}`);
			}
		});
	}

	filterNavigation(navItems: DashboardSectionMenu[], activeLink: string): BreadcrumbItem[] {
		for (const section of navItems) {
			const breadcrumbList = this.processSection(section, activeLink);
			if (breadcrumbList.length > 0) {
				return breadcrumbList;
			}
		}
		return [];
	}

	private processSection(section: DashboardSectionMenu, activeLink: string): BreadcrumbItem[] {
		const breadcrumbList: BreadcrumbItem[] = [];

		for (const item of section.items) {
			if (this.isMenuItem(item) && item.routerLink === activeLink) {
				breadcrumbList.push({ title: item.label, link: item.routerLink, type: item.kind });
			} else if (this.isMenuSet(item)) {
				const setBreadcrumbList = this.processMenuSet(item, activeLink);
				if (setBreadcrumbList.length > 0) {
					breadcrumbList.push(...setBreadcrumbList);
					return breadcrumbList;
				}
			}
		}

		return breadcrumbList;
	}

	private processMenuSet(menuSet: DashboardMenuSet, activeLink: string): BreadcrumbItem[] {
		for (const item of menuSet.menuItems) {
			if (this.isMenuItem(item) && item.routerLink === activeLink) {
				return [{ title: item.label, link: item.routerLink, type: item.kind }];
			}
		}
		return [];
	}

	private isMenuItem(item: any): item is DashboardMenuItem {
		return item && item.kind === 'menuItem' && 'routerLink' in item;
	}

	private isMenuSet(item: any): item is DashboardMenuSet {
		return item && item.kind === 'set' && 'menuItems' in item;
	}
}
