import { UtilityService } from "@eforall/common";
import { GridColumn } from "../interfaces";
import { boolCol, dateCol, getColConfig, numberCol, stringCol } from "./get-col-config";


export function buildColumn<RowT extends { updatedUTC: number }, ValueT extends Date | undefined>(
	util: UtilityService,
	col: dateCol,
	getValue: (row: RowT) => ValueT,
	overrides?: Partial<GridColumn<RowT, ValueT>>,
): GridColumn<RowT, unknown>;

export function buildColumn<RowT extends { updatedUTC: number }, ValueT extends number | undefined>(
	util: UtilityService,
	col: numberCol,
	getValue: (row: RowT) => ValueT,
	overrides?: Partial<GridColumn<RowT, ValueT>>,
): GridColumn<RowT, unknown>;

export function buildColumn<RowT extends { updatedUTC: number }, ValueT extends string | undefined>(
	util: UtilityService,
	col: stringCol,
	getValue: (row: RowT) => ValueT,
	overrides?: Partial<GridColumn<RowT, ValueT>>,
): GridColumn<RowT, unknown>;

export function buildColumn<RowT extends { updatedUTC: number }, ValueT extends boolean | undefined>(
	util: UtilityService,
	col: boolCol,
	getValue: (row: RowT) => ValueT,
	overrides?: Partial<GridColumn<RowT, ValueT>>,
): GridColumn<RowT, unknown>;

export function buildColumn<RowT extends { updatedUTC: number }, ValueT extends boolean | Date | string | number>(
	util: UtilityService,
	col: boolCol | dateCol | numberCol | stringCol,
	getValue: (row: RowT) => ValueT,
	overrides: Partial<GridColumn<RowT, ValueT>> = {},
): GridColumn<RowT, unknown> {

	const config = getColConfig<RowT>(util, col);
	const defaultCol: GridColumn<RowT, unknown> = { getValue, ...config, flatRowField: '' };

	return { ...defaultCol, ...overrides } as GridColumn<RowT, unknown>;
}