<div class="dashboard-toolbar-part" [class.menu-collapsed]="layout.menuCollapsed()">
	<div class="row header-wrapper align-items-center">
		<div class="col col-sm-6 col-md-8 d-flex justify-content-start p-0">
			@if(layout.menuCollapsed()|| layout.dimensions().windowWidth <= BREAKPOINT_MD){ <div class='toolbar-logo' routerLink='/'>
				<dashboard-logo-part logo="small"></dashboard-logo-part>
		</div>
		}
		<button class='btn' ngbTooltip="Collapse" placement='bottom' (click)="toggleMenu()">
			<i class="fa-solid fa-bars"></i>
		</button>

		<button class='btn' ngbTooltip="Search" placement='bottom' (click)="openSearch()">
			<i class="fa-regular fa-magnifying-glass"></i>
		</button>
		<div class='toolbar-app-name px-1'>
			{{envService.getEnvironment()?.appName}}
		</div>
	</div>
	<div class="col col-sm-6 col-md-4 d-flex justify-content-end p-0 pe-2">
		@if(frame.config()?.toolbarButtons?.length){

				<div class="toolbar-config-buttons" [class.only-one]="frame.config()?.toolbarButtons?.length === 1">
					@for(button of frame.config()?.toolbarButtons; track button){
						<button class='btn' ngbTooltip='{{button.tooltip}}' placement='bottom' (click)="button.callback()">
							<i class='{{button.iconStyle}} {{button.icon}}'></i>
						</button>
					}
				</div>
			


			<div ngbDropdown class="toolbar-config-buttons-dropdown" [class.only-one]="frame.config()?.toolbarButtons?.length === 1" [autoClose]="'outside'">
				<button class="btn" ngbTooltip="List" placement='bottom' ngbDropdownToggle id="toolbarButtonsDropdown">
					<i class="fa-solid fa-ellipsis-vertical"></i>
				</button>
				<div ngbDropdownMenu aria-labelledby="toolbarButtonsDropdown" class="dropdown-menu dropdown-menu-end">
					@for(button of frame.config()?.toolbarButtons; track button){
						<button ngbDropdownItem class='btn nav-link' (click)="button.callback()">
							<common-icon-text [iconStyle]="button.iconStyle" icon="fa-fw {{button.icon}}" [text]="button.label"
								spacing="large"></common-icon-text>
						</button>
					}
				</div>
			</div>
		}

		<button class='btn' ngbTooltip="Notifications" placement='bottom' (click)="openAlerts()" disabled>
			<i class="fa-regular fa-bell">
				@if(frame.config()?.notifications?.length){
				<span
					class="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle"
					style="font-size: 10px; font-weight: 400;">
					{{frame.config()?.notifications?.length}}
					<span class="visually-hidden">New alerts</span>
				</span>
				}
			</i>
		</button>


		<div ngbDropdown class="user-menu" [autoClose]="'outside'">
			<button class="btn" ngbTooltip="User Menu" placement='bottom' ngbDropdownToggle id="userMenu">
				<i class="fa-regular fa-chevron-down"></i>
			</button>
			<div ngbDropdownMenu aria-labelledby="userMenu" class="dropdown-menu dropdown-menu-end">
				<a ngbDropdownItem href="https://myaccount.google.com/" target="my-account" class="nav-link">
					<common-icon-text iconStyle="fa-regular" icon="fa-fw fa-user" text="My Account"
						spacing="large"></common-icon-text>
				</a>
				<a ngbDropdownItem href='https://chat.google.com/room/AAAAlOvAAAc' target='eforall-app-help'
					class="nav-link">
					<common-icon-text iconStyle="fa-regular" icon="fa-fw fa-user-headset" text="Support"
						spacing="large"></common-icon-text>
				</a>
				<a ngbDropdownItem href='https://ableproadmin.com/angular/default/dashboard/default'
					target='dashboard-template' class="nav-link">
					<common-icon-text iconStyle="fa-regular" icon="fa-fw fa-link" text="Template"
						spacing="large"></common-icon-text>
				</a>
				<a ngbDropdownItem (click)="authService.signOut()" class="nav-link">
					<common-icon-text iconStyle="fa-regular" icon="fa-fw fa-power-off" text="Sign Out"
						spacing="large"></common-icon-text>
				</a>
			</div>
		</div>
	</div>
</div>