/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withViewTransitions } from '@angular/router';
import { BootstrapPage } from './pages/bootstrap/bootstrap.page';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './routes';
import { importProvidersFrom } from '@angular/core';


bootstrapApplication(
	BootstrapPage,
	{
		providers: [
			provideRouter(routes, withViewTransitions()),
			importProvidersFrom([BrowserAnimationsModule]),
			// provideRouter(routes, withViewTransitions(), withDebugTracing()),
		]
	}
).catch((err) => console.error(err));
