import { Component, HostBinding, computed, input } from '@angular/core';

@Component({
	selector: 'common-icon-text',
	standalone: true,
	templateUrl: './common-icon-text.widget.html',
	styleUrls: ['./common-icon-text.widget.scss'],
})
export class CommonIconTextWidget {

	public readonly iconStyle = input<'fa-regular' | 'fa-solid'>('fa-regular');
	public readonly icon = input('fa-caret-right');
	public readonly spacing = input<'small' | 'large'>('small');
	public readonly iconSize = input<'small' | 'large'>('small');
	public readonly text = input.required<string>();

	public readonly iconClass = computed(() => `fa-fw ${this.iconStyle()} ${this.icon()}`);


	@HostBinding('class.spacing-large') get largeGap() {
		return this.spacing() == 'large' ? 'spacing-large' : '';
	}

	@HostBinding('class.icon-large') get largeIcon() {
		return this.iconSize() == 'large' ? 'icon-large' : '';
	}

}