<div class="alert fade show d-flex align-items-center p-2 mb-0" role="alert" [ngClass]="{
      'alert-info': alertType() === 'info',
      'alert-success': alertType() === 'success',
      'alert-warning': alertType() === 'warning',
      'alert-danger': alertType() === 'error',
      'alert-dismissible': closeable()
    }" [hidden]="closeable() && closed">
	<i class="fa-fw fa-solid me-2 fs-5" [ngClass]="{
      'fa-info-circle': alertType() === 'info',
      'fa-circle-check': alertType() === 'success',
      'fa-triangle-exclamation': alertType() === 'warning',
      'fa-circle-x': alertType() === 'error',
    }"></i>
	<ng-content></ng-content>
	@if(closeable()){
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
		(click)="closed = true;"></button>
	}
</div>