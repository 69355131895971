<div ngbDropdown (openChange)="onOpenChange($event)">

	<button type="button" class="btn btn-standard" id="selector" ngbDropdownToggle>
		@if(selectedItem(); as item )
		{
		<common-icon-text [iconStyle]="item.iconStyle" [icon]="item.icon" [text]="item.label"
			spacing="large"></common-icon-text>
		}
		@else {
		<common-icon-text iconStyle="fa-regular" icon="fa-arrow-down" [text]="selectLabel()"
			spacing="large"></common-icon-text>
		}
	</button>

	<div ngbDropdownMenu aria-labelledby="selector" class="common-selector-dropdown" style="transition: transform 100ms;">
		@for(item of items(); track item.id;)
		{
		<button ngbDropdownItem (click)="selectedId.set(item.id)" [class.selected]="item.id === selectedItem()?.id">
			<common-icon-text [iconStyle]="item.iconStyle" [icon]="item.icon" [text]="item.label"
				spacing="large"></common-icon-text>
		</button>
		}
	</div>

</div>