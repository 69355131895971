<div class="dashboard-error-unauthorized-page container-fluid">
	<div class="row justify-content-center">
		<div class="col-12 col-sm-8 col-md-6 col-lg-4">
			<div class="card">
				<div class="card-body text-center">
					<h2 class="display-3">
						<!-- <me-label key='Unauthorized'></me-label> -->
						Unauthorized
					</h2>
					<h6 class="card-title">
						<!-- <me-label key='Access Not Allowed'></me-label> -->
						Access Not Allowed
					</h6>
					<p class="card-text text-left">
						<!-- <me-label key='md:Special Permission Message'></me-label> -->
						Special Permission Message
					</p>
					<button class="btn" type="button" (click)="goToDashboard()">
						<!-- <me-label key='Go to the Dashboard'></me-label> -->
						Go to the Dashboard
					</button>
					<p></p>
				</div>
			</div>
		</div>
	</div>
</div>