import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EnvironmentService } from '@eforall/common';
import { DashboardFrameService } from '../dashboard-frame.service';
import { DashboardLayoutService } from '../layout/dashboard-layout.service';
import { DashboardLogoPart } from '../logo/dashboard-logo.part';
import { DashboardMenuAccordionPart } from './menu-accordion/dashboard-menu-accordion.part';
import { DashboardMenuItemPart } from './menu-item/dashboard-menu-item.part';


@Component({
	selector: 'dashboard-menu-part',
	standalone: true,
	imports: [DashboardMenuItemPart, DashboardMenuAccordionPart, DashboardLogoPart, RouterModule],
	templateUrl: './dashboard-menu.part.html',
	styleUrl: './dashboard-menu.part.scss'
})


export class DashboardMenuPart {
	public envService = inject(EnvironmentService);
	public frame = inject(DashboardFrameService);
	public layout = inject(DashboardLayoutService);
}
