import { AngularEnvironment } from './angular-environment';

export const environment: AngularEnvironment = {
	name: 'DEV',
	appName: 'Admin',
	baseUrl: 'https://admin-dev.eforall.org/',
	bodyCssClasses: [],
	frame: 'Dashboard',
	authType: 'Internal',
	supportsSpanish: false,
	functionUrl: "https://us-central1-eforall-admin-dev.cloudfunctions.net/",
	firebaseConfig: {
		apiKey: "AIzaSyA8SbzXJZlTI7RbZcA0P26zEXgNJUwKNoQ",
		authDomain: "admin-dev.eforall.org",
		projectId: "eforall-admin-dev",
		storageBucket: "eforall-admin-dev.appspot.com",
		messagingSenderId: "968585547188",
		appId: "1:968585547188:web:e75c8cc8228092f018b6e1",
		measurementId: "G-HJRGX6BGG4",
	},
};
