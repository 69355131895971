/**
 * Convert an array of NPS ratings to a score, where each rating is 0..10
 * @param standardScoring If true, then a standard NPS score ranging from -100 to 100 will be returned. If false, the score will be translated into a range of 0 to 100. Default: false
 */
export function _calcScore(ratings: number[], standardScoring = false) {

	if (!ratings) return null;
	if (ratings.length == 0) return null;

	let promoters = 0;
	let detractors = 0;

	for (const rating of ratings) {
		if (rating >= 9) promoters++;
		else if (rating <= 6) detractors++;
	}

	const count = ratings.length;

	let score = promoters / count - detractors / count;
	score = Math.round(score * 100);

	if (standardScoring == false) score = score / 2 + 50;

	return score;
}