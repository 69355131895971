import { Component, inject } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { DashboardGridContentWidget } from '@eforall/dashboard';
import { AppService } from 'services';
import { getSetup } from './grid/get-setup';


@Component({
	selector: 'functions-page',
	imports: [DashboardGridContentWidget],
	standalone: true,
	templateUrl: './functions.page.html'
})

export class FunctionsPage {

	public readonly app = inject(AppService);
	private readonly util = inject(UtilityService);

	public setup = getSetup(this.util);

}