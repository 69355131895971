import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService, ErrorService, SpinnerService } from '@eforall/common';
import { DashboardFrameService } from './dashboard-frame.service';
import { DashboardErrorPart } from './error/error.part';
import { DashboardLayoutPart } from './layout/dashboard-layout.part';


@Component({
	selector: 'dashboard-frame',
	standalone: true,
	imports: [
		CommonModule,
		DashboardErrorPart,
		DashboardLayoutPart,
		RouterOutlet,
	],
	templateUrl: './dashboard.frame.html',
	styleUrl: './dashboard.frame.scss'
})
export class DashboardFrame {

	public errorService = inject(ErrorService);
	public auth = inject(AuthService);
	public frame = inject(DashboardFrameService);
	public spinner = inject(SpinnerService);

}
