import { DashboardFrameConfig } from '@eforall/dashboard';
import { environment } from '../../environments/environment';
import { AppService } from '../../services';
import { DEPARTMENT_MENU, INTERACTION_MENU, SOFTWARE_MENU, STAFF_MENU } from './menus';


export function getDashboardFrameConfig(app: AppService): DashboardFrameConfig {
	return {
		appName: environment.appName,
		includeBreadcrumb: false,
		menu: [
			INTERACTION_MENU,
			STAFF_MENU,
			DEPARTMENT_MENU,
			SOFTWARE_MENU,
		],

		toolbarButtons: [
			{
				key: 'reload-admin',
				iconStyle: 'fa-regular',
				icon: 'fa-arrows-rotate',
				tooltip: 'Reload',
				label: 'Reload',
				callback: async () => {
					await app.loadData();
				},
			},
		],

		notifications: [],
	}
}