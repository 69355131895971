import { Staff } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { GridSetup } from '@eforall/dashboard';
import { getActions } from './get-actions';
import { getButtons } from './get-buttons';
import { getColumns } from './get-columns';


export function getSetup(util: UtilityService): GridSetup<Staff> {

	return {
		rowName: { singular: 'Staff', plural: 'Staff' },
		getRowKey: row => '' + row.staffId,
		stateKey: 'staff-grid',
		actions: getActions(),
		buttons: getButtons(),
		columns: getColumns(util),
		renderRowClasses: row => row.status == 'Active' ? [] : ['grid-cell-strikethrough', 'grid-cell-gray-text'],
	}
}
