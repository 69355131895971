import { Signal, WritableSignal, signal } from "@angular/core";


let _canRequestSignal: WritableSignal<boolean>;
let _canExitSignal: WritableSignal<boolean>;

/**
 * Create signals and update them whenever fullscreenchange is fired
 */
function buildSignals() {

	_canRequestSignal = signal(FullscreenUtilities.canRequest());
	_canExitSignal = signal(FullscreenUtilities.canExit());

	document.documentElement.addEventListener('fullscreenchange', () => {
		_canRequestSignal.set(FullscreenUtilities.canRequest());
		_canExitSignal.set(FullscreenUtilities.canExit());
	});

	return {
		canRequest: _canRequestSignal,
		canExit: _canExitSignal,
	};
}


export const FullscreenUtilities = {


	canRequest(): boolean {
		if (!document.fullscreenEnabled) return false;
		if (document.fullscreenElement) return false;
		return true;
	},


	getCanRequestSignal(): Signal<boolean> {

		//
		// If we have already initialized and created the singleton signal, return it.
		//
		if (_canRequestSignal) return _canRequestSignal.asReadonly();

		//
		// Otherwise, create a signal and an event handler to monitor fullscreen changes.
		//
		const signals = buildSignals();
		return signals.canRequest.asReadonly();
	},


	canExit(): boolean {
		if (FullscreenUtilities.canRequest()) return false;
		if (!document.fullscreenEnabled) return false;
		if (document.fullscreenElement) return true;
		return false;
	},


	getCanExitSignal(): Signal<boolean> {

		//
		// If we have already initialized and created the singleton signal, return it.
		//
		if (_canExitSignal) return _canExitSignal.asReadonly();

		//
		// Otherwise, create a signal and an event handler to monitor fullscreen changes.
		//
		const signals = buildSignals();
		return signals.canExit.asReadonly();
	},


	/**
	 * Will attempt to set the document to full screen with a locked orientation.
	 * Returns whether it was successful or not.  Failure to lock the orientation
	 * will fail silently, with an error logged to the console.
	 */
	async request(orientation: 'portrait' | 'landscape'): Promise<boolean> {

		if (!FullscreenUtilities.canRequest()) return false;

		//
		// If already full screen, request it
		//
		if (!document.fullscreenElement) {
			await document.documentElement.requestFullscreen({ navigationUI: 'hide' });
			if (!document.fullscreenElement) return false;
		}

		//
		// Try to lock orientation
		//
		try {
			// @ts-expect-error ts(2339)
			await screen.orientation.lock(orientation);
		}
		catch (e) {
			console.error(e);
		}

		return true;
	},


	/**
	 * Stop fullscreen mode and return to normal viewing.
	 */
	async exit(): Promise<boolean> {

		if (!FullscreenUtilities.canExit()) return false;

		await document.exitFullscreen();
		return true;

	},

};
