import { decompressFromUTF16 } from 'lz-string';
import { AuthService } from '../auth';
import { EnvironmentService } from '../env';
import { ErrorService } from '../error';
import { SpinnerService } from '../spinner';
import { SPANISH, UrlService } from '../url';
import { UtilityService } from '../util';
import { FuncActionResult, FuncCallBody, FuncClientFlags } from './interfaces';
import { stringify } from './stringify';
// import { v4 as uuidv4 } from 'uuid';

const MAX_ATTEMPTS = 10;


/**
 * This is the base class for the function shims.  Each shim uses the base
 * _call function which makes the remote call and implements any client flag
 * functionality.  To add additional client flags, add them to FuncClientFlags
 * interface and add implementation here.
 */
export class FuncServiceBase {

	constructor(
		private env: EnvironmentService,
		private auth: AuthService,
		private errorService: ErrorService,
		private spinnerService: SpinnerService,
		private url: UrlService,
		private util: UtilityService,
	) {
	}



	/**
	 * Shim call with no payload.  If the action function has a payload of void, null or undefined
	 * then this shim call will be used instead and the shim will not take a parameter.
	 */
	protected _callNoPayload = <OUT>(path: string, flags: FuncClientFlags = { label: 'LOADING' }): () => Promise<OUT> => {
		const call = this._call<void, OUT>(path, flags);
		return () => call(undefined);
	}


	/**
	 * Shim call with a payload.  The shim will have a single parameter of type IN. The type can
	 * be a JSON object with properties and those values can be accessed through destructuring.
	 */
	protected _call = <IN, OUT>(action: string, flags: FuncClientFlags = { label: 'LOADING' }): (payload: IN) => Promise<OUT> => {

		const spin = !flags.noPageSpinner;

		const shimFunc = async <IN, OUT>(payload: IN): Promise<OUT> => {

			const _handleError = (reject: (reason?: unknown) => void, activity: string, error: string, errorName?: string) => {
				this.errorService.setError(activity, error, errorName, action, payload);
				if (reject) reject(errorName);
			}

			try {
				const token = await this.auth.getIdToken();

				return new Promise<OUT>((resolve, reject) => {

					const req = new XMLHttpRequest();
					let attempt = 1;


					//
					// The network request returned back successfully, but there may still be an application error
					//
					req.onload = () => {

						if (spin) this.spinnerService.removeSpinner(action);

						if (req.status == 501) {
							//
							// NOTHING TO DO
							//
							// A duplicate attempt was detected in the backend in the checkForDuplicateCall
							// node middleware. The prior call attempt that got through has either already
							// completed and then resolved or rejected the promise or it is still pending
							// and will come back soon.
							//
						}
						else if (req.status === 200) {
							try {
								const responseText = decompressFromUTF16(req.responseText);
								const response = <FuncActionResult<OUT>>JSON.parse(responseText);

								resolve(response.payload);
							}
							catch (error) {

								if (error instanceof Error) {
									_handleError(reject, 'Response FrontEnd Error', error.message);
								}
								else _handleError(reject, 'Response FrontEnd Error Message', error);

							}
						} else {

							const responseText = req.responseText;
							let appError: string | undefined = responseText;

							try {
								const responseText = decompressFromUTF16(req.responseText);
								const response = <FuncActionResult<string>>JSON.parse(responseText);
								if (response.errorStack) console.error(response.errorStack);
								appError = response.payload;
							}
							catch {
								/** Continue regardless of error */
							}

							_handleError(reject, 'Response BackEnd Error', appError || '', `HTTP${req.status} - ${req.statusText}`);
						}
					};


					//
					// The network request failed for some unknown reason
					//
					req.onerror = () => {

						if (attempt < MAX_ATTEMPTS) {

							const error = `${action} - Attempt #${attempt} failed. Will try again in ${attempt} sec.`;
							console.error(error);

							setTimeout(() => {
								attempt++;
								message.callAttempt = attempt;

								req.open('POST', this.env.getEnvironment().functionUrl + 'action', true);
								req.setRequestHeader('Authorization', 'Bearer ' + token);
								req.setRequestHeader("Content-Type", "application/json");

								req.send(stringify(message));

							}, attempt * 1000);

						}
						else {

							if (spin) this.spinnerService.removeSpinner(action);

							const error = `${action} - Failed - Couldn't connect with ${MAX_ATTEMPTS} attempts.`;
							_handleError(reject, 'Network Error', error, 'Failed to Connect')
						}
					};


					//
					// The request was aborted
					//
					req.onabort = () => {

						if (spin) this.spinnerService.removeSpinner(action);

						this.util.log.errorMessage(`func.${action} - Aborted`);

						reject(`func.${action}() aborted!`);
					}


					req.open('POST', this.env.getEnvironment().functionUrl + 'action', true);
					req.setRequestHeader('Authorization', 'Bearer ' + token);
					req.setRequestHeader("Content-Type", "application/json");

					let spinLabel = 'Reading';

					if (this.url.languageId() == SPANISH) {
						if (flags.label == 'LOADING') spinLabel = 'Cargando';
						if (flags.label == 'UPDATING') spinLabel = 'Actualizando';
					}
					else {
						if (flags.label == 'LOADING') spinLabel = 'Loading';
						if (flags.label == 'UPDATING') spinLabel = 'Updating';
					}

					if (spin) this.spinnerService.addSpinner(action, spinLabel);

					const message: FuncCallBody<IN> = {
						payload,
						languageId: this.url.languageId(),
						action,
						callAttempt: attempt,
					};

					req.send(stringify(message));
				});
			}
			catch (error) {
				if (error instanceof Error) {
					_handleError(() => undefined, 'Function Preparation Error', error.message);
				}
				return Promise.reject('Error preparing call to Function Service');
			}
		}

		shimFunc.funcClientFlags = flags;
		shimFunc.action = action;

		return shimFunc;
	}
}