import { computed, Signal } from '@angular/core';


export function getCommonLabels(isEnglish: Signal<boolean>) {

	const en = isEnglish;

	return {

		/** Common EforAll terms */
		common: {
			Calendar: computed(() => en() ? `Calendar` : `{{ ES: Calendar }}`),
			Cohort: computed(() => en() ? `Cohort` : `{{ ES: Cohort }}`),
			Cohorts: computed(() => en() ? `Cohorts` : `{{ ES: Cohorts }}`),
			Demographics: computed(() => en() ? `Demographics` : `{{ ES: Dempgraphics }}`),
			EforAll: computed(() => en() ? `EforAll` : `EparaTodos`),
			NotAuthorized: computed(() => en() ? `Not Authorized` : `{{ ES: Not Authorized }}`),
			ProgramManager: computed(() => en() ? `Program Manager` : `{{ ES: Program Manager }}`),
			Specialist: computed(() => en() ? `Specialist` : `Especialista`),
			Topic: computed(() => en() ? `Topic` : `Tema`),
			Venture: computed(() => en() ? `Venture` : `{{ ES: Venture }}`),
			Ventures: computed(() => en() ? `Ventures` : `{{ ES: Ventures }}`),
		},

		/** Standard labels used in forms */
		form: {
			Answer: computed(() => en() ? `Answer` : `{{ ES: Answer }}`),
			Email: computed(() => en() ? `Email` : `{{ ES: Email }}`),
			Phone: computed(() => en() ? `Phone` : `{{ ES: Phone }}`),
			Question: computed(() => en() ? `Question` : `{{ ES: Question }}`),
			Save: computed(() => en() ? `Save` : `{{ ES: Save }}`),
			SelectEllipses: computed(() => en() ? `Select...` : `{{ ES: Select... }}`),

		},

		/** Standard labels used on buttons */
		button: {
			Apply: computed(() => en() ? 'Apply' : 'Aplica'),
			ApplyInLanguage: computed(() => en() ? 'Solicite en español' : 'Apply in English'),
			LearnMore: computed(() => en() ? 'Learn More' : 'Aprende más'),
			Next: computed(() => en() ? `Next` : `{{ ES: Next }}`),
			Previous: computed(() => en() ? `Previous` : `{{ ES: Previous }}`),
			Save: computed(() => en() ? `Save` : `{{ ES: Save }}`),
		}

	};
}