import { Staff } from "@app-interfaces";
import { UtilityService } from '@eforall/common';
import { GridColumn, buildColumn } from '@eforall/dashboard';


export function getColumns(util: UtilityService) {

	const columns: GridColumn<Staff, unknown>[] = [
		buildColumn(util, 'Id', row => row.staffId, { hidden: false, header: 'Column' }),
	];


	return columns;

}