
export function stringArrayEquality(a: string[], b: string[]) {

	if (a === b) return true;
	if (a == undefined && b == undefined) return true;
	if (a == undefined || b == undefined) return false;
	if (a.length !== b.length) return false;
	if (a.length == 0) return true;

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false;
	}
	
	return true;
}