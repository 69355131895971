import { GridButton } from '@eforall/dashboard';


export function getButtons() {

	const buttons: GridButton[] = [
		{
			iconClass: 'fa-triangle',
			label: 'Triangle',
			execute: () => { console.log('Triangle Button Clicked!') }
		},
		{
			iconClass: 'fa-circle',
			label: 'Circle',
			execute: () => { console.log('Circle Button Clicked!') }
		},
	];

	return buttons;
}