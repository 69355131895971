import { GridAction } from '@eforall/dashboard';
import { Staff } from "@app-interfaces";


export function getActions() {

	const actions: GridAction<Staff>[] = [
		{
			iconClass: 'fa-regular fa-pencil',
			label: 'Edit Message',
			isEnabled: rows => !!rows.length,
			execute: async rows => { console.log(`Action clicked!`, rows) }
		},
	];

	return actions;
}