import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DashboardContentBorderWidget } from '../../widgets/content/content-border';
import { DashboardBreadcrumbPart } from '../breadcrumb/dashboard-breadcrumb.part';
import { DashboardMenuPart } from '../menu/dashboard-menu.part';
import { DashboardToolbarPart } from '../toolbar/dashboard-toolbar.part';
import { DashboardLayoutService } from './dashboard-layout.service';


@Component({
	selector: 'dashboard-layout-part',
	standalone: true,
	imports: [
		RouterOutlet,
		DashboardBreadcrumbPart,
		DashboardContentBorderWidget,
		DashboardToolbarPart,
		DashboardMenuPart,
	],
	templateUrl: './dashboard-layout.part.html',
	styleUrl: './dashboard-layout.part.scss'
})


export class DashboardLayoutPart {

	public layout = inject(DashboardLayoutService);
}
