import { Injectable, inject } from "@angular/core";
import { NavigationEnd, NavigationError, Router } from "@angular/router";
import { Analytics, EventParams, Item, logEvent } from 'firebase/analytics';

/**
 * 
 *  See EventNameString in analytics-public.d.ts
 * 
 'add_payment_info'
 'add_shipping_info'
 'add_to_cart'
 'add_to_wishlist'
 'begin_checkout'		-- CREATE A NEW APPLICATION (item == Accelerator or PitchContest)
 'checkout_progress'	-- MAYBE FOR SECTIONS OF THE APPLICATIONS?
 'exception'			-- ERRORS
 'generate_lead'
 'login'				-- LOGIN
 'page_view'			-- ROUTER NAVIGATION
 'purchase'				-- APPLICATION SUBMISSION (USE `applicationId:${applicationId}` for unique key)
 'refund'
 'remove_from_cart'
 'screen_view'			
 'search'
 'select_content'
 'select_item'
 'select_promotion'
 'set_checkout_option'
 'share'
 'sign_up'
 'timing_complete'
 'view_cart'
 'view_item'
 'view_item_list'
 'view_promotion'
 'view_search_results'
 */


/**
 * Service to log events to Google Analytics
 */
@Injectable({ providedIn: 'root' })
export class AnalyticsService {

	private _router = inject(Router);
	private _analytics: Analytics | undefined = undefined;


	/**
	 * The AuthService initializes Firebase Analytics right after the
	 * Firebase App is created. It passes the Analytics object here to
	 * be used for recording events.
	 */
	setAnalytics(analytics: Analytics) {
		this._analytics = analytics;
	}


	constructor() {
		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) this.logNavigationEnd(event);
			else if (event instanceof NavigationError) this.logNavigationError(event);
		})
	}


	/**
	 * Log a {@link https://developers.google.com/analytics/devguides/collection/ga4/views Page View} to Analytics.
	 */
	private logNavigationEnd(event: NavigationEnd) {

		if (!this._analytics) return;

		//
		// TODO: Determine if this should be manually logged or if GA4 is configured
		// to automatically log page views and referrers.  It's probably better to
		// use the automatic tracking, unless there is extra metadata to add.
		//

		const url = new URL(event.urlAfterRedirects, location.origin);

		logEvent(this._analytics, 'page_view', {
			page_title: url.pathname,
			page_location: event.urlAfterRedirects,
			page_path: event.url,
		});
	}


	/**
	 * Log a {@link https://developers.google.com/analytics/devguides/collection/ga4/views Page View} to Analytics.
	 */
	private logNavigationError(event: NavigationError) {

		if (!this._analytics) return;

		this.logException({
			description: `Navigation Error`,
			fatal: true,
			error: event.error,
			url: event.url,
			target: event.target,
		});
	}


	/**
	 * Log a login event to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logLogin(eventParams: {
		email: string,
		fromPersistence: boolean,
		method?: EventParams['method'];
		[key: string]: any;
	}) {

		if (!this._analytics) return;
		logEvent(this._analytics, 'login', eventParams);
	}


	/**
	 * Log an exception event with details from an Error object.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logError(e: Error) {
		this.logException({
			description: `${e.name}: ${e.message}`,
			name: e.name,
			message: e.message,
			stack: e.stack,
			fatal: true,
		});
	}

	/**
	 * Log an exception event to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logException(eventParams: {
		description: EventParams['description'];
		fatal: EventParams['fatal'];
		[key: string]: any;
	}) {

		if (!this._analytics) return;
		logEvent(this._analytics, 'exception', eventParams);
	}


	/**
	 * Log a newly created accelerator application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logAccApplicationStarted(accId: number, accName: string, siteId: number, siteName: string, applicationId: number,) {
		this.logApplicationStarted('Acc', accId, accName, siteId, siteName, applicationId);
	}


	/**
	 * Log a newly created pitch contest application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logPicApplicationStarted(picId: number, picName: string, siteId: number, siteName: string, applicationId: number,) {
		this.logApplicationStarted('Pic', picId, picName, siteId, siteName, applicationId);
	}


	/**
	 * Log a newly created application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	private logApplicationStarted(
		program: 'Acc' | 'Pic',
		id: number,
		name: string,
		siteId: number,
		siteName: string,
		applicationId: number,
	) {

		if (!this._analytics) return;

		const items: Item[] = [
			{
				item_id: '' + id,
				item_name: name,
				affiliation: siteName,
				location_id: '' + siteId,
			}
		];

		logEvent(this._analytics, 'begin_checkout', {
			description: `${program} Application Started`,
			value: 0,
			items,
			applicationId,
		});
	}


	/**
	 * Log a submitted accelerator application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logAccApplicationSubmitted(accId: number, accName: string, siteId: number, siteName: string, applicationId: number) {
		this.logApplicationSubmitted('Acc', accId, accName, siteId, siteName, applicationId);
	}


	/**
	 * Log a submitted accelerator application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	logPicApplicationSubmitted(picId: number, picName: string, siteId: number, siteName: string, applicationId: number) {
		this.logApplicationSubmitted('Pic', picId, picName, siteId, siteName, applicationId);
	}


	/**
	 * Log a submitted application to Analytics.
	 * See the {@link https://developers.google.com/gtagjs/reference/ga4-events the GA4 reference documentation}.
	 */
	private logApplicationSubmitted(
		program: 'Acc' | 'Pic',
		accId: number,
		accName: string,
		siteId: number,
		siteName: string,
		applicationId: number,
	) {

		if (!this._analytics) return;

		const items: Item[] = [
			{
				item_id: '' + accId,
				item_name: accName,
				affiliation: siteName,
				location_id: '' + siteId,
			}
		];

		logEvent(this._analytics, 'purchase', {
			description: `${program} Application Submitted`,
			transaction_id: '' + applicationId,
			affiliation: siteName,
			value: 0,
			items,
			applicationId,
		});
	}
}