import { ImmutableArray } from "../../../types/deep-readonly";


/**
 * Given an array of numbers, it returns out a new array with any undefined
 * elements and duplicates filtered out. The new array will be sorted numerically
 * by default but the optional second parameter can be used to skip the sorting.
 */
export function cleanNumericIds(original: ImmutableArray<number> | number[], sort = true): number[] {

	if (!original) return [];

	//
	// Remove undefined values (including 0)
	//
	let elements = original.filter(element => !!element);


	//
	// Remove duplicates
	//
	elements = [...new Set(elements)];


	//
	// Sort for faster sql reading
	//
	if (sort) elements.sort((e1, e2) => e1 - e2);

	return elements;
}



/**
 * Given an array of strings, it returns out a new array with any undefined
 * elements and duplicates filtered out. The new array will be sorted textually
 * by default but the optional second parameter can be used to skip the sorting.
 */
export function cleanStrings(original: ImmutableArray<string> | string[], sort = true): string[] {

	if (!original) return [];

	//
	// Remove undefined values
	//
	let elements = original.filter(element => !!element);


	//
	// Remove duplicates
	//
	elements = [...new Set(elements)];


	//
	// Sort for faster sql reading
	//
	if (sort) elements.sort();

	return elements;
}