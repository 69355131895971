import { JobRole } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { GridSetup } from '@eforall/dashboard';
import { getActions } from './get-actions';
import { getButtons } from './get-buttons';
import { getColumns } from './get-columns';


export function getSetup(util: UtilityService): GridSetup<JobRole> {

	return {
		rowName: { singular: 'Job Role', plural: 'Job Roles' },
		getRowKey: row => '' + row.jobRoleId,
		stateKey: 'job-roles-grid',
		actions: getActions(),
		buttons: getButtons(),
		columns: getColumns(util),
	}
}
