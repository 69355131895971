//
// These must align with the values in *-variables.scss
//

/** Same as $breakpoint-SM-min in *-variables.scss */
export const BREAKPOINT_SM = 600;
/** Same as $breakpoint-MD-min in *-variables.scss */
export const BREAKPOINT_MD = 1000;
/** Same as $breakpoint-LG-min in *-variables.scss */
export const BREAKPOINT_LG = 1200;
/** Same as $breakpoint-XL-min in *-variables.scss */
export const BREAKPOINT_XL = 1600;



/** Width of the left menu */
export const MENU_WIDTH = 180;

/** Height of the bar area at the top of the page. It contains the hamburger menu and user menu. */
export const TOOLBAR_HEIGHT = 54;

/** Padding around the content displayed */
export const CONTENT_PADDING = 10;